.shop-sidebar{
    display: flex;
    flex-direction: column;
    height: fit-content;
    gap: 15px;
    background-color: white;
    border-radius: 8px;
       padding: 15px;
      --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
width: 22%;
min-width:230px;
}