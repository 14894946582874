.sidebar {
    position: fixed;
    right: 0;
    z-index: 1500;
    background-color: rgb(255, 255, 255);
    width: 410px;
    height: 100vh;
    box-sizing: border-box;
   padding: 25px 30px 25px 30px;
    display: flex;
    flex-direction:column ;
    transform: translateX(100%);
    transition: all 0.3s;
    box-shadow: 0;
    overflow: auto;
  }
  .sidebar--open{
transform: translateX(0%);
box-shadow: 0 0 5px 5px rgb(0 0 0 / 10%);
  }
.sidebar--open h2{
  border-bottom: 1px solid rgba(156, 156, 156, 0.37);
  padding-bottom: 15px;
  font-size: 20px;
}
.sidebar--open > span:first-child{
  margin-left: auto;
  cursor: pointer;
  font-size: 1.2em;
  font-weight: lighter;
}
.sidebar--open > span:first-child:hover{
  color: var(--main-light-color);
}
.sidebar--open > span:first-child{
  font-size: 1.2em;
  color: var(--main-dark-color);
  font-weight: bold;
}
  .panier-items ul{
    display: flex;
    flex-direction: column;
    list-style: none;
  }
  .panier-items ul li{
    display: flex;
    gap: 7px;
    border-bottom: 1px solid rgba(156, 156, 156, 0.37);
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .panier-items ul li div:first-child{
    height: 80px;
    width: 80px;
    min-width: 80px;
    border: 1px solid rgba(128, 128, 128, 0.473);
    border-radius: 3px;
    padding: 5px;
    text-align: center;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }

  .panier-items ul li div:first-child img{
    width: 45px;
    height: auto;
    vertical-align: middle;
  }
  .panier-items ul li div:nth-child(2){
    display: flex;
    flex-direction: column;
gap: 10px;
  }
  .panier-items ul li div:nth-child(2) span:first-child{
    font-size: 14px;
    
  }
  .panier-items ul li div:nth-child(2) span:nth-child(2){
    font-weight: 550;
color: rgb(46, 45, 45);
font-size: 1em;
font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  .x-icon{
    margin-left: auto;
    cursor: pointer;
    
  }
  .x-icon:hover{
    color: red;
  }
  .sidebar--open button{
    background-color:white ;
    height: 45px;
    min-height: 45px;
    border: 2px solid var(--main-light-color);
    border-radius: 5px;
    font-size: 1.1em;
    margin-top: 25px;
    width: 100%;
  }
  .sidebar--open button:hover{
    background-color: var(--main-light-color);
  }
  .sous-total{
    display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  }
  .sous-total span:first-child{
    font-weight:500;
    font-size: 1em;
    color: gray;
  }
  .sous-total span:nth-child(2){
    font-weight: 550;
    color: rgb(46, 45, 45);
    font-size: 1.05em;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  .no-products{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  .no-products span:first-child{
    font-size: 4em;
  }
  .no-products span:nth-child(2){
    font-size: 1.3em;
  }
  @media (max-width:768px) {
    .sidebar {
      width:100%;
      padding: 25px 30px 30% 30px;
    }
  }


  .sidebar--open::-webkit-scrollbar {
    width: 10px;
  }
  
  .sidebar--open::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
  }
  
  .sidebar--open::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 100px;
  }