
.contact-container{
width: 100%;
height: 100%;
padding: 8rem 30px 30px 30px;
display: flex;
flex-direction: column;
}
.contact-container .bg{
 z-index: -1;
 background-image: url("../../../assets//bg-talya.png");
background-position:center center;
-webkit-transition: opacity 1s;
background-repeat: no-repeat;
background-size: cover;
height: 100%;
left: 0;
position: absolute;
top: 0;
width: 100%;
}
.title-contact{
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    
}
.title-contact button{
    width: 220px;
    height: 42px;
    background-color: var(--main-light-color);
    padding: 5px;
    border-radius: 5px;
    border: 1px solid transparent;
    font-size: 1.1em;
    font-weight: bold;
    color: var(--main-dark-color);
}
.title-contact button:hover{
   background-color: #ad8c1d;
}

.email-phone{
    display: flex;
    justify-content: center;
    gap: 40px;
    margin-top: 5rem;
    
}
.email-phone > div{
    width: 36%;
    height: 350px;
    background-color: #fff;
    border-radius: 8px;
    padding: 40px 20px 10px 20px ;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.7rem;
    text-align: center;
    --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
}
.email-phone h2{
font-size: 1.2em;
font-weight: 700;
}
.cercle-icon{
    font-size: 3em;
    color: var(--main-dark-color);
}
.email-phone  div span:first-child{
color: gray;
font-size: 0.5em;

}
.email-phone span:last-child{
    color: var(--main-light-color);
    font-weight: bold;
    font-size: 1.1em;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


input[type=number] {
  -moz-appearance: textfield;
}
.message{
    display: flex;
    flex-direction: column;
    width: calc(72% + 40px);
    gap: 25px;
    background-color: white;
    border-radius: 8px;
    padding: 35px;
    margin-top: 2rem;
    margin-left: auto;
  margin-right: auto;
  --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
}
.message form{
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.message h2{

    font-size: 1.1em;

}
.message div{
    display: flex;
    gap: 15px;
}
.message input{
    width: 50%;
    height: 55px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid rgba(172, 172, 172, 0.712);
    font-size: 1em;
    font-size: 0.9em;
    outline: none;
}
.message input:focus{
    border: 1px solid var(--main-light-color);
}
.message textarea{
    height: 140px;
    resize: vertical;
    border-radius: 8px;
    border: 1px solid rgba(172, 172, 172, 0.712);
    outline: none;
}
.message textarea:focus{
    border: 1px solid var(--main-light-color);
}
.message button{
    width: 220px;
    height: 42px;
    background-color: var(--main-light-color);
    padding: 5px;
    border-radius: 5px;
    border: 1px solid transparent;
    font-size: 1.05em;
    color: var(--main-dark-color);
    align-self: center;
}

.message button:hover{
background-color: #ad8c1d;}
@media (max-width:600px) {
    .email-phone{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .email-phone > div{
        width: 90%;
    }
    .message {
        width: 90%;
        padding: 20px;
    }
    .message div{
        display: flex;
        flex-direction: column;
    }
    .message input{
        width: 100%;
    }
    .contact-container{
        padding: 8rem 10px 30px 10px;
  
        }
        .contact-container .bg{
            height: 80%;
        }
}