.account-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height:100%;
    padding:40px 0px 50px 0px;
}
.account-container > div{

    display: flex;
    height:450px;
    width: 65%;
    min-width: 800px;
    background-color: white;
    border-radius: 8px;
    --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);

}
.account-nav{
    position: relative;
    left: 0;
    width: 320px;
    height: 100%;
    border-right: 1px solid rgba(190, 190, 190, 0.404);
}
.account-nav ul{
    display: flex;
    flex-direction: column;
    list-style: none;
   height: 100%;
   width: 100%;
}
.account-nav ul li{
    border-bottom: 1px solid rgba(168, 168, 168, 0.5);
    display: flex;
    height: 12%;
    align-items: center;
   padding-left: 17px;
   cursor: pointer;
   color: rgb(88, 86, 86);
   text-decoration: none;
   font-size: 14px;
   font-weight: bold;
}
.account-nav ul li div{
  display: flex;
  align-items: center;
  gap: 18px;
}
.account-nav ul li div span{
  font-size: 1.5em;

}
.account-nav ul li:hover{
    background-color: #f9f9f9;
}
.account-nav ul li.active{
    border-left: 3px solid var(--main-light-color);
    color: var(--main-light-color);
}
.account-nav .top-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 22px;
    gap: 10px;
    border-bottom: 1px solid rgba(190, 190, 190, 0.404);
}
.account-nav .top-section .logo {
    width: 100px;
    height: 100px;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    border: 3px solid rgba(190, 190, 190, 0.404);
  }
  .edit-icon{
    position: absolute;
     top: 15px;
     right: 40px;
    font-size: 1.5em;
    color:rgb(88, 86, 86) ;
  }
  .edit-icon:hover{
    color: var(--main-light-color);
  }
  .edit-icon label{
    cursor: pointer;
  }
  .edit-icon label:hover{
color: var(--main-light-color);
  }
  .account-nav .top-section .logo img {
   
    width: 100%;
  height: 100%;
   border-radius: 50%;
   object-fit: cover;
  }
  .username{
    font-weight: bold;
    font-size: 1.1em;
    text-align: center;
    color: var(--main-dark-color);
  }
  .outlet{
    width: 100%;
    overflow: auto;
   
  }
  @media (max-width:800px) {
    .account-container > div{
        flex-direction: column;
        min-width: 100%;
        width: 100%;
        height:100%;
        border-radius: 0px;
    }
    .account-nav{
        position: relative;
        left: 0;
        width: 100%;
    }
    .outlet{
        width: 100%;
        height: fit-content;
      }
      .account-nav ul li{
        height: 50px;
      }
      .edit-icon{
      
         top: 5px;
         right: 36%;
  
      }
  }