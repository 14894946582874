
    .swipers-container{
        --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
        width:45%;
            background-Color: #fff;
            padding: 20px;
            border-radius: 8px;
            z-index: 0;
    }
.product-images-slider .swiper-slide {
    overflow: hidden;  
    margin-bottom: 15px; 
    position: relative;
    padding-top: 100%;
   
}
    .product-images-slider .swiper-slide img{
        width: 60%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);         
        height: auto;    
        
    }
   .product-images-slider .swiper-button-prev {
        left: 0;
        color: var(--main-light-color);
      
    }
    .product-images-slider .swiper-button-next {
        right: 0;
        color: var(--main-light-color);
       
    }
      
    .product-images-slider-thumbs .swiper-slide {
        cursor: pointer;
        border-radius: 5px;
        border: 1px solid #dddbdb;
        text-align: center;
        height: 150px;;
    }

        .product-images-slider-thumbs .swiper-slide-thumb-active {
            border-color: var(--main-light-color);
            border-radius: 5px;
        }
        .product-images-slider-thumbs-wrapper{
            width: 100%;
           height: 100px;
            overflow: hidden;
            /* padding: 8px; */
        
        }
        .product-images-slider-thumbs img{
            display: flex;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);     
            width: 60%;
            height: auto;
        }


    @media (max-width:850px) {
        .swipers-container{
            width: 100%;
        }
    }