.sideadmin {
   position: fixed;
    left: 0;
    background-color: rgb(255, 255, 255);
    width: 250px;
    height: 100vh;
    padding-top: 30px;
    border-right: 1px solid #aeb6c1;
  }
  .logo {
    width: 40%;
    height: auto;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
  }
  .logo img {
    max-width: 100%;
    max-height: 100%;
  }
  .sideadmin .elements-container ul {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 15px;
  }

  .sideadmin .elements-container .element.active {
    border-left: 3px solid var(--main-light-color);
    border-radius: -15%;
  }

  .sideadmin .elements-container .element {
    color: rgb(88, 86, 86);
    display: block;
    text-decoration: none;
    padding-left: 20px;
  }
  .sideadmin .elements-container .element span {
    position: absolute;
    display: inline-block;
    line-height: 50px;
    font-size: 13px;
    font-weight: bold;
  }
  .sideadmin .elements-container .element:hover {
  color: var(--main-light-color);
    background-color: rgba(158, 157, 157, 0.24);
  }


  .sideadmin .elements-container .element .icony {
    display: inline-block;
    text-align: center;
    line-height: 50px;
    font-size: 18px;
    width: 20px;
    margin-right: 1.8rem;
    margin-left: 1rem;
    
  }
  .sideadmin .elements-container ul button{
    width: 70%;
    height: 40px;
    border-radius: 8px;
    border: 1px solid transparent;
    padding: 5px;
    background-color: var(--main-light-color);
   align-self: center;
   font-weight: bold;
   color: black;
   margin-top: 15px;
  }
  .sideadmin .elements-container ul button:hover{
    background-color: #a58827;
  }
  

