@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Scada&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mandali&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@900&display=swap);
html{scroll-behavior: smooth}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root{
  --main-dark-color:#0c1013;
  --main-light-color:#d3ad32;
}
body {
  font-family: "Poppins", sans-serif;
  background-color: #f9f9f9;

}
body.active-modal {
  overflow-y: hidden;
}


.vertical{
    border-left: 3px solid var(--main-dark-color);
    height: 80px;
    position: absolute;
    left: 50%;
margin-top: 20px;
   
}
.vertical2{
    border-left: 3px solid var(--main-dark-color);
    height: 80px;
    position: relative;
    left: 50%;

margin-bottom: 10px;
   
}
button{
    cursor: pointer;
}
.container{
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.navbar{

    z-index: 999;
    position:fixed;
    width: 100%;
    top: 0;
   justify-content: space-between;
    height: 60px;
    align-items: center;
    padding: 20px 20px 20px 40px;
    display: flex;
    right: 0;
    font-size: 15px;
    font-weight: bold;
    background-color:#0c1013;

}
.navbar.active{
    box-shadow: 5px 5px 15px #e5ba6479;
}
.navbar span a{
    text-decoration: none;
    color: var(--main-light-color);
    font-size: 18px;
}
.navbar ul{
    display: flex;
    list-style: none;
    align-items: left;
   }
   .navbar ul li {
    display: flex;
    margin:0 30px;
    align-items: center;
    grid-gap: 15px;
    gap: 15px;
   }
   .navbar ul li div{
    cursor: pointer;
   }
   .link{
    text-decoration: none;
    color: white;
   }
   .navbar a:hover{
    color: var(--main-light-color);
   }
   .nav-mobile{
       display: flex;
       
   }
   .nav-mobile span{
    color: var(--main-light-color);
    font-size: 1.5em;
   }
   .navigation-menu{
   position: absolute;
   padding: 8px;
   top: 60px;
   left: 0;
   display: flex;
   width: 0;
   height: -webkit-fit-content;
   height: -moz-fit-content;
   height: fit-content;
   background-color:var(--main-dark-color);
   border-top: 1px solid black;
   transition:  0.3s ;
   opacity: 0;
   }
   .navigation-menu.active{
   width: 35%;
   opacity: 1;
    
   }
   .navigation-menu ul{
    opacity: 0;
   }
   .navigation-menu.active ul{
    opacity: 1;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    padding: 10px 0px 0px 20px;
   }
   .navigation-menu ul li{
    margin: 0;
   }
   
   .navigation-menu ul li {
    margin: 0;
  }
  .navigation-menu  li a {
    color: white;
    text-decoration: none;
  }
  .navigation-menu li:hover {
    background-color: #eee;
  }
   .link-active{
    text-decoration: none;
    color: var(--main-light-color);
   }
   header{
    height: 100vh;
    width:100vw ;
    display: flex;
    justify-content:center;
    grid-gap: 150px;
    gap: 150px;
    align-items: center;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
     background-image: url(/static/media/bg-talya.aa0ca73b.png);

    }
    header img{
        width: clamp(250px,25%,30%);
        height: auto;
    }
    header .right{
        display: flex;
        flex-direction: column;
        grid-gap: 25px;
        gap: 25px;
        
       
    }
    .right div{
        margin-top: 1rem;
    }
 
   header h1{
    color: white;
    font-size: 55px;
    font-weight: bold;
    font-family: 'Dancing Script', cursive;
    /* font-weight: bold;
    font-family: 'Mandali', sans-serif; */
   }
    header button{
        /* padding: 10px 70px 10px 70px; */
        padding-left: 2rem;
        width: 260px;
        height: 45px;
        display: flex;
        align-items: center;
        grid-gap: 20px;
        gap: 20px;
        margin: 10px;
        border-radius: 5px;
        border: 1px solid transparent;
        font-size: 20px;
      font-family: "poopins",sans-serif;
        overflow-y: hidden;
    }
    header button span{
       font-size: 26px;
       text-align: center;
       line-height: 8px;
    }
    .connexion-button{
        background-color: var(--main-light-color);
        color:white;
        font-size: 21px;
        font-weight: bold;
        width: 260px;
    }
    .connexion-button:hover{
        background-color: var(--main-light-color);
        cursor: pointer;
    }
    .acheter{
        background-color: white;
        color:var(--main-dark-color);
        font-size: 21px;
        font-weight: bold;
        grid-gap: 25px;
        gap: 25px;
    }
    .acheter:hover{
        background-color: rgba(255, 255, 255, 0.87);
        cursor: pointer;
    }
    .services {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        grid-gap: 10px;
        gap: 10px;  
        margin-top: 2rem;  
    }
    .services > h3{
        font-size: 2em;
    }
    .services > div{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        grid-gap: 50px;
        gap: 50px;
        margin-top: 2rem;
    }
    .services div div{
        display: flex;
        flex-direction: column;
        height: 240px;
        width: 240px;
        padding: 15px;
        border:2px solid grey;
        border-radius: 15px;

        align-items: center;
        text-align: center;
        transition-duration: 0.2s;
    }
    .services div div:hover{
        -webkit-transform: scale(1.05);
                transform: scale(1.05);
    }
    .services div span{
        background-color: var(--main-light-color);
        font-size: 40px;
    color: #0c1013;
        height: 55px;
        width: 55px;
        border-radius: 50%;
    }
    .About-us{      
        margin:120px 20px 30px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        grid-gap: 25px;
        gap: 25px;
    }
    .About-us h3{
        font-size: 2em;
    }
    .About-us p{
        padding: 20px;
        width: 70%;
        line-height: 3rem;
        font-size: 1.1em;
        text-align: center;
        background-color: var(--main-light-color);
        color: #120E5E;
        border-radius: 10px;
    }
    .footer{
        display: flex;
        flex-direction: column;
        grid-gap: 30px;
        gap: 30px;
     position: relative;
     bottom: 0;
     width: 100%;
        /* margin-top: 4.8rem; */
        padding: 40px;
        background-color: #0c1013;
        color: white;
    }
    .footer > div{
        display: flex;
        flex-wrap: wrap;
    }
    .first-part{
        justify-content: space-evenly;
    }
    .footer div div{
        display: flex;
        flex-direction:  column;
        
    }
    .footer span{
        font-size: 15px;
        color: gray;
    }
   
    hr.thin {
        height: 1px;
        border: 0;
        background-color: gray;
        width: 80%;
      align-self: center;
        }
        .last-part{
            justify-content: space-evenly;
            grid-gap: 200px;
            gap: 200px;
        }
        .sauviez-nous span{
            font-size: 35px;
            text-align: center;
        }

        .modal, .overlay {
            z-index: 1250;
            width: 100vw;
            height: 100vh;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: fixed;
            ; 
        }
        .overlay {
            background-color: rgba(0,0,0,0.5);
        }
        .modal-content {
          
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            transition: opacity 5s ;
            line-height: 1.4;
            background: #f1f1f1;
            padding: 10px 28px;
            border-radius: 8px;
            width: 600px;
            /* min-height: 400px;
            max-height: 500px; */
            height: 520px;
            /* overflow-y: auto; */
            
        
        }
   
        .modal-content{
            -webkit-animation-name: example;
                    animation-name: example;
            -webkit-animation-duration: 0.4s;
                    animation-duration: 0.4s;
        }
        @-webkit-keyframes example {
            0%   {  top:0;opacity:0.2}
            100%  { top:50%;opacity: 1;}}
        @keyframes example {
            0%   {  top:0;opacity:0.2}
            100%  { top:50%;opacity: 1;}}
        
        .close-modal {
            position: absolute;
            top: 10px;
            right: 10px;
            padding: 5px 7px;
            cursor: pointer;
        }
   .contact-title{
    text-align: center;
    font-size: 2em;
   }
     .contact_us{
        padding: 40px;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        background-color: #080a0c;
        grid-gap: 20px;
        gap: 20px;
        margin-top: 20px;
     }
     .first-contact{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        flex-wrap: wrap;
     }
    .first-contact div{
        width: 400px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px;
        grid-gap: 10px;
        gap: 10px;
        font-size: 1em;
        background-color: var(--main-light-color);
        border-radius: 10px;
        color: var(--main-dark-color);
        font-weight: bold;    
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }
     .first-contact div img{
        width: 12%;
        vertical-align: middle;
        height: auto;
     }
     .second-contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-gap: 10px;
        gap: 10px;
      
     }
     .second-contact {
        color: white;
        font-size: 1.3em;
     }
     .second-contact form{
        display: flex;
        flex-direction: column;
        
        grid-gap: 10px;
        
        gap: 10px;
     }
     .second-contact form div {
        display: flex;
        flex-direction: column;
     }
     .second-contact form div label{
        color: white;
        font-size: 0.9em;      
     }
     .second-contact form div input{
        width: 400px;
        height: 40px;
        border-radius: 5px;
        border: 2px solid transparent;
        background-color: #625D56;
        padding-left: 10px;
        font-size: 1em;
        color: white;
     }
     .flag{
        display: flex;
        font-weight: bold;
        color: white;
        align-items: center;
        grid-gap: 5px;
        gap: 5px;
        cursor: pointer;
     }
     .flags{
        display: flex;
        grid-gap: 18px;
        gap: 18px;
        align-items: center;
     }
     .flags-mobile{
        display: flex;
        flex-direction: column;
     }
   textarea{
    border-radius: 5px;
    height: 100px;
    padding: 10px;
    font-size: 1em; 
   }
   .second-contact form button{
      width: 50%;
      height: 35px;
      border: 1px solid transparent;
      border-radius: 5px;
      background-color: var(--main-light-color);
      font-weight: bold;
      font-size: 1em;
      color: #120E5E;
      font-family: "poopins",sans-serif;
      align-self: center;
   }
   .elsou9{
    display: flex;
    align-items: center;
    grid-gap: 15px;
    gap: 15px;
   }
   .elsou9 img{
    width: 30px;
    height: auto;  
   }
.scrolldown{
    position: absolute;
    bottom: 0px;
    left: 50%;
    width: 30px;
    height: 30px;
    -webkit-transform: translateY(-80px) translateX(-50%) rotate(45deg);
            transform: translateY(-80px) translateX(-50%) rotate(45deg);
}
.scrolldown span{
    position: absolute;
    top: 0;
    left: 0;
    display: bock;
    width: 100%;
    height: 100%;
    border-bottom: 2px solid var(--main-light-color);
    border-right: 2px solid var(--main-light-color);
    -webkit-animation: animate 1s linear infinite ;
            animation: animate 1s linear infinite ;
}
.scrolldown span:nth-child(1){
    -webkit-transform: translate(-15px,-15px);
            transform: translate(-15px,-15px);
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
}
.scrolldown span:nth-child(2){
    -webkit-transform: translate(0,0);
            transform: translate(0,0);
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
}
.scrolldown span:nth-child(3){
    -webkit-transform: translate(15px,15px);
            transform: translate(15px,15px);
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
}
@-webkit-keyframes  animate 
{
   0%{
    top:-5px ;
    left: -5px;
    opacity: 0;
   }
   25%{
    top:0px ;
    left: 0px;
    opacity: 1;
   }
   50%,100%{
    top:5px ;
    left: 5px;
    opacity: 0;
   }
    
}
@keyframes  animate 
{
   0%{
    top:-5px ;
    left: -5px;
    opacity: 0;
   }
   25%{
    top:0px ;
    left: 0px;
    opacity: 1;
   }
   50%,100%{
    top:5px ;
    left: 5px;
    opacity: 0;
   }
    
}

@media (max-width:920px){
    .first-contact{
        grid-gap: 25px;
        gap: 25px;
    }
}
   
    @media(max-width: 768px) {  
        .navbar{
            right: 0;
            left: 0;
           justify-content: center;
           padding: 20px 0px 20px 0px;     
        }
        header{
            display: flex;
            flex-direction: column;
           justify-content:center;
           padding-top:20px;
           grid-gap: 40px;
           gap: 40px;
            background: #0c1013;
        }
        header img{
            width: 55%;
            height: auto;
        }
        .About-us p{
            width: 90%;
        }
        .first-part{
            flex-direction: column;
            grid-gap: 20px;
            gap: 20px;
       text-align: center;
        }
        .last-part{
            grid-gap: 50px;
            gap: 50px;
        }
        header .right{
            align-items: center;
        }
        header h1{
           text-align: center;  
        }
        .first-contact{
            grid-gap: 15px;
            gap: 15px;
            width: 100%;
        }
        .first-contact div{
            width: 100%;
            height: -webkit-fit-content;
            height: -moz-fit-content;
            height: fit-content;
        }
        .second-contact{
            width: 100%;
        }
        .second-contact form {
         width: 100%;
        }
        .second-contact form div input{
            width: 100%;
         }
    }

.container_sign{
    font-family: "Poppins", sans-serif;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 2000;
}
.head{
    height: 90px;
    display: flex;
   align-items: center;
   justify-content: space-between;
   padding-left: 20px;
   padding-right: 20px;
    background-color: var(--main-dark-color);
    font-size: 2.5em;
}
.head a{
    color: var(--main-light-color);
}
.head span{
  color: var(--main-light-color);
  justify-self: start;
}
.head img{
   width: 45px;
   height: auto;  
   margin-top: 10px;  
}
.title{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f7f7f7;
    height: 60px;
    color: var(--main-dark-color);
    font-weight: 900;
    font-size: 20px;
}

.connexion{
 padding: 10px;
 width: 100%;
}
.connexion::-webkit-scrollbar {
    width: 12px;
  }
  
  .connexion::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
  }
  
  .connexion::-webkit-scrollbar-thumb {
    background-color: var(--main-light-color);
    border-radius: 100px;
  }
.info{
    display: flex;
    flex-direction: column;
    width: 100%;
}

   
.info i{
    margin-left: auto;
    display: inline-block;
    margin-right: 15px;
    margin-top: -56px;
    margin-bottom: 30px;
    font-size: 1.4em;
}
.first-info{
    display: flex;
}
.connexion input{
    height: 40px;
    margin-bottom: 25px;
    border: 2px solid var(--main-dark-color);
    border-radius: 5px;
    padding: 6px;
    background-color: white;
    font-size: 1em;
}
.connexion form > div input:focus{
    border: 2px solid var(--main-light-color);
    outline: none;
}
.connexion label{
    color:  var(--main-dark-color);
    font-weight: bold;
}
 
.connexion form button{
    height: 40px;
   padding: 10px;
   background-color: var(--main-light-color);
   color:var(--main-dark-color);
   font-weight: 900;
   font-size: 15px;
   display: flex;
  align-items: center;
  justify-content: center;
   border: 1px solid transparent;
   border-radius: 5px;
   width: 100%;
}
.connexion .text{
    margin-top: 2rem;
   text-align: center;

}
.connexion h4{
    margin-top: 20px;
    cursor: pointer;
  font-size: 1.2em;
}
.connexion .text span:first-child{
    color: #f5b639; 
    cursor: pointer;
}
.social{
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 20px;
}
.social button{
    width: 100%;
    background-color: white;
    border:2px solid gray;
    height: 50px;
    font-weight: bold;
    font-size: 17px;
    display: flex;
    align-items: center;
    padding-left: 15px;
 border-radius: 5px;
}
.social button i{
    color: blue;
    font-size: 22px;
    position: relative;
    left: 0;
}
.social button span{
    justify-self: center;
    font-family: "Poppins", sans-serif;
    width: 100%;
    text-align: center;
    padding-right: 15px;
}
.first-info {
    display: flex;
    justify-content: space-between;
    
}
.pass-reset p{
    cursor: pointer;
}
.first-info div{
    display: flex;
    flex-direction: column;
}

.pass-reset{
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
}
.pass-reset h3{
    text-align: center;
}
.pass-reset div{
    display: flex;
    flex-direction: column;
}
.pass-reset div label{
    color:  var(--main-dark-color);
    font-weight: bold;
}
.pass-reset div input{
    height: 40px;
    margin-bottom: 25px;
    border: 2px solid var(--main-dark-color);
    border-radius: 5px;
    padding: 6px;
    background-color: white;
    font-size: 1em;
}
.pass-reset button{
    height: 40px;
    padding: 10px;
    background-color: var(--main-light-color);
    color:var(--main-dark-color);
    font-weight: 900;
    font-size: 15px;
    display: flex;
   align-items: center;
   justify-content: center;
    border: 1px solid transparent;
    border-radius: 5px;
    width: 100%;
}
.pass-reset button:hover{
    background-color: #a88b2b;
}
@media  (min-width: 800px){
.social{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.social button{
    grid-gap: 10px;
    gap: 10px;
}
.social button i{
  line-height: 15px;
}}


@media (max-width:768px) {
    .first-info{
        display: flex;
        flex-direction: column;
      
    }
    .container_sign{
        height: 100vh;
    }
    .connexion{
        padding-top: 3rem;
    }
 
}


.nav-mobile{
    height: 60px;
    width: 100%;
    background-color: var(--main-dark-color);
    display: flex;
  position: -webkit-sticky;
  position: sticky;
    top: 0;
    z-index: 1200;
    align-items: center;
   padding-left: 15px;
   padding-right: 15px;
}
.nav-mobile .logo{
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
  }
.nav-mobile .logo img:first-child{
    width: 30px;
    height: auto;
}
.nav-mobile .logo img:nth-child(2){
    width: 90px;
    height: auto;
}
.nav-mobile span{
    cursor: pointer;
}
.nav-mobile .logo span{
    font-weight: bold;
   display: flex;
}

.sidemobile {
    position: fixed;
  
    left: 0;
    top: 0;
    z-index: 3;
    background-color: rgb(255, 255, 255);
    width: 70%;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction:column ;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    transition: all 0.2s;
  }
  .sidemobile--open{
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    box-shadow: 0 0 5px 5px rgb(0 0 0 / 10%);
}
.sidemobile--open ul{
    list-style: none;
    display: flex;
    flex-direction: column;
   
}
.sidemobile  span{
    padding: 15px;
    color: var(--main-dark-color);
    font-weight: bold;
    cursor: pointer;
    margin-left: auto;
    font-size: 1.2em;
}
.sidemobile--open ul li{
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;  
    border-bottom: 1px solid rgba(187, 187, 187, 0.274); 
    padding: 10px;
 
    color: var(--main-dark-color);
    font-weight: 500;
    font-size: 14px;
    cursor: pointer; 
}
.sidemobile--open ul li:hover{
    background-color:rgba(128, 128, 128, 0.425) ;
}
.sidemobile--open ul li:first-child{
    border-top: 1px solid rgba(187, 187, 187, 0.274);
  
}
.shopping-cart{
    color: var(--main-light-color);
    font-size: 1.6em;
    cursor: pointer;
}





.user-avatar {
 display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    cursor: pointer;
    color: var(--main-dark-color);
    }
.user-avatar span:first-child{
    font-weight: 600;
    font-size: 1em;
    color: var(--main-dark-color);
    font-family: "poopins",sans-serif;
}
.user-avatar span:last-child{
    font-size: 1.4em;
    color:var(--main-dark-color);
}
.avatar-icon{
    width: 40px;
    height: 40px;
    overflow: hidden;   
     margin-left: auto;
    margin-right: auto;
  
}
.avatar-icon img{
    width: 100%;
    height: 100%;
     border-radius: 100%;
     object-fit: cover;
}
.menu-user-avatar{
    position: absolute;
    margin: 10px 0;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 170px;
    
    display: none;
}
.menu-user-avatar.active{
    display: block;
}
.menu-user-avatar div{
    padding: 6px 14px 6px 14px ;
    display: flex;
    align-items: center;
    justify-content: space-between; 
    cursor: pointer;
}
.menu-user-avatar div span:first-child{
    font-size: 0.8em;
    font-weight: 600;
    color: gray;
}
.menu-user-avatar div span:nth-child(2){
    font-size: 1.3em;
    vertical-align: middle;
    color: gray;
}
.menu-user-avatar div:first-child{
    border-bottom: 1px solid rgba(172, 172, 172, 0.452);
}
.menu-user-avatar div:hover{
    background-color: rgba(196, 196, 196, 0.315);
}
.nav-client{
   z-index: 1;
   display: flex;
   flex-direction: column;
   height: 125px;
     width: 100%;
    font-family: "poopins",sans-serif;
    transition: top 0.6s;
}
.nav-client nav{
    width: 100%;
    padding: 0 50px;
}

.top-nav{
    height: 75px;  
    background-color: white;
   
}
.top-nav-hidden{
    display: none;
}
.top-nav ul{
    display: flex;
    list-style: none;
    height: 100%;
    justify-content: space-evenly;
    align-items: center;
}
.elsou9-logo{
    width: 50px;
    height: auto;
}
.elsou9-text{
    width: 140px;
    height: auto;
}
.top-nav ul li:nth-child(2){
   flex: 0.6 1;
}
.search-container{
    width:100% ;
  
   
}
.search-container  div:first-child{
    width: 90%;
}
.search-container input{
width: 90%;
    height: 40px;
    border: 1px solid transparent;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    padding: 10px;
    vertical-align: middle;
}
.search-result{

    width: 28%;
 
   min-width: 150px;
   min-width: none;
position: absolute;
box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    z-index: 1000;
    background-color: white;
 border-radius: 5px;
 margin-top: 8px;
}
.search-result ul{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}
.search-result ul li {
   
    display: flex;
    align-items: center;
    padding: 8px 13px 8px 13px ;
    justify-content:  space-between;
    width: 100%;
    border-bottom: 1px solid rgba(207, 207, 207, 0.24);
}
.search-result ul li:hover {
    background-color: rgba(194, 194, 194, 0.308);
}
.search-result ul li:last-child{
    border: 1px solid transparent;
    font-weight: lighter;
}
.search-result ul li > span{
    font-weight: bold;
    font-size: 0.95em;
}
.search-result ul li div {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
   
}
.search-result ul li div span{
    font-weight: 500;
    font-size: 0.85em;
    color: gray;
}
.search-result ul li div img{
    object-fit: contain;
 height: 55px;
 width: 50px;
 border-radius: 3px;
 background-color: #f1f0f0;
}
.top-nav .search-container button{
    --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
    box-shadow: 0 0 rgba(0, 0, 0, 0),0 0 rgba(0, 0, 0, 0),var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
border: 2px solid transparent;    
    height: 40px;
    vertical-align: middle;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #fada52;
    padding: 8px 10px 10px;
      vertical-align: middle;          
    font-size: 1.1em;
    text-align: center;
   
}
.top-nav .search-container button:hover{
    background-color: #c4a233;
}
.top-nav span{
  
cursor: pointer;
}
.top-nav > ul > li:nth-child(4){
    display: flex;
    align-items: center;
    grid-gap: 5px ;
    gap: 5px ;
    color: var(--main-dark-color);
}

.top-nav > ul > li:nth-child(4):hover{
    color: var(--main-light-color);
}
.top-nav > ul > li:nth-child(4) span:first-child{
    font-size: 1.45em;
    line-height: 8px;
}
.top-nav > ul > li:nth-child(4) span:last-child{
    font-size: 1.1em;
}
.top-nav > ul > li:nth-child(4):hover{
    color: var(--main-light-color);
    cursor: pointer;
}
.top-nav > ul > li:first-child {
display: flex;
grid-gap: 5px;
gap: 5px;
align-items: center;
}
.top-nav > ul > li:first-child img{
    vertical-align: middle;
}
.top-nav .flags{
    grid-gap: 10px;
    gap: 10px;
}
.top-nav .flag{
    color: var(--main-dark-color);
    font-weight: 600;
    font-size: 0.9em;
}
.bottom-nav{
   
    height: 50px;
    width: 100%;
    background-color: var(--main-dark-color);

}
.bottom-nav a{
    text-decoration: none;
    color: white;
    font-weight: 600;
    font-size: 0.9em;
}
.bottom-nav ul{
    display: flex;
    justify-content: center;
    grid-gap: 100px;
    gap: 100px;
    list-style: none;
    height: 100%;
    align-items: center;
}
.bottom-nav ul li a:hover{
    color: var(--main-light-color);
}
.login{
    -webkit-animation: test;
            animation: test;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
}
@-webkit-keyframes test {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
@keyframes test {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}


.search-container input,.search-container button{
    --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
box-shadow: 0 0 rgba(0, 0, 0, 0),0 0 rgba(0, 0, 0, 0),var(--tw-shadow);
box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
outline: none;
}
.search-container div:first-child{

    display: flex;
}
.connect{
    background-color: var(--main-light-color);
    padding: 10px;
    border: 1px solid transparent;
   font-size: 0.95em;
   border-radius: 5px;
   font-weight: bold;
   color: white;
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.connect:hover{
    background-color: #c4a233;
}

.sidebar {
    position: fixed;
    right: 0;
    z-index: 1500;
    background-color: rgb(255, 255, 255);
    width: 410px;
    height: 100vh;
    box-sizing: border-box;
   padding: 25px 30px 25px 30px;
    display: flex;
    flex-direction:column ;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    transition: all 0.3s;
    box-shadow: 0;
    overflow: auto;
  }
  .sidebar--open{
-webkit-transform: translateX(0%);
        transform: translateX(0%);
box-shadow: 0 0 5px 5px rgb(0 0 0 / 10%);
  }
.sidebar--open h2{
  border-bottom: 1px solid rgba(156, 156, 156, 0.37);
  padding-bottom: 15px;
  font-size: 20px;
}
.sidebar--open > span:first-child{
  margin-left: auto;
  cursor: pointer;
  font-size: 1.2em;
  font-weight: lighter;
}
.sidebar--open > span:first-child:hover{
  color: var(--main-light-color);
}
.sidebar--open > span:first-child{
  font-size: 1.2em;
  color: var(--main-dark-color);
  font-weight: bold;
}
  .panier-items ul{
    display: flex;
    flex-direction: column;
    list-style: none;
  }
  .panier-items ul li{
    display: flex;
    grid-gap: 7px;
    gap: 7px;
    border-bottom: 1px solid rgba(156, 156, 156, 0.37);
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .panier-items ul li div:first-child{
    height: 80px;
    width: 80px;
    min-width: 80px;
    border: 1px solid rgba(128, 128, 128, 0.473);
    border-radius: 3px;
    padding: 5px;
    text-align: center;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }

  .panier-items ul li div:first-child img{
    width: 45px;
    height: auto;
    vertical-align: middle;
  }
  .panier-items ul li div:nth-child(2){
    display: flex;
    flex-direction: column;
grid-gap: 10px;
gap: 10px;
  }
  .panier-items ul li div:nth-child(2) span:first-child{
    font-size: 14px;
    
  }
  .panier-items ul li div:nth-child(2) span:nth-child(2){
    font-weight: 550;
color: rgb(46, 45, 45);
font-size: 1em;
font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  .x-icon{
    margin-left: auto;
    cursor: pointer;
    
  }
  .x-icon:hover{
    color: red;
  }
  .sidebar--open button{
    background-color:white ;
    height: 45px;
    min-height: 45px;
    border: 2px solid var(--main-light-color);
    border-radius: 5px;
    font-size: 1.1em;
    margin-top: 25px;
    width: 100%;
  }
  .sidebar--open button:hover{
    background-color: var(--main-light-color);
  }
  .sous-total{
    display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  }
  .sous-total span:first-child{
    font-weight:500;
    font-size: 1em;
    color: gray;
  }
  .sous-total span:nth-child(2){
    font-weight: 550;
    color: rgb(46, 45, 45);
    font-size: 1.05em;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  .no-products{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  .no-products span:first-child{
    font-size: 4em;
  }
  .no-products span:nth-child(2){
    font-size: 1.3em;
  }
  @media (max-width:768px) {
    .sidebar {
      width:100%;
      padding: 25px 30px 30% 30px;
    }
  }


  .sidebar--open::-webkit-scrollbar {
    width: 10px;
  }
  
  .sidebar--open::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
  }
  
  .sidebar--open::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 100px;
  }
.category{
    padding: 8px 8px 2px 8px;
    display: block;
    transition: background-color .15s;
    border-radius: 5px;
    cursor: pointer;
  }
  .category a{
    text-decoration: none;
    color: var(--main-dark-color);
  }
  .category-title{
    display: flex;
    font-size: 1.1em;
    justify-content: space-between;
  }
  .category-title a{
    font-size: 0.9em;
  }
  .category-title a:hover{
    text-decoration: underline;
  }
  .category-title i{
    cursor: pointer;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }
  .category.open .category-title i{
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .sous-category{
    display: flex;
    flex-direction: column;
    padding-top: .25em;
    height: 0;
    overflow: hidden;
    padding-left:10px ;
  }
  .category.open .sous-category{
    height: auto;
  }
  .category.plain{
    color: var(--main-dark-color);
    text-decoration: none;
    
  }
  .categories {
    display: flex;
    flex-direction: column;
    width: 100%;

  }
  .categories h2{
    font-size: 1em;
    text-align: center;
    
  }
  hr.solid2 {
    border-top:2px solid rgba(187, 187, 187, 0.644);
    width: 20%;
    margin-top: 10px;
     align-self: center;
     margin-bottom: 10px;
}
hr.solid3{
    border-top:1px solid rgba(187, 187, 187, 0.377);
    width: 100%;
     text-align: center;
}
@media (max-width:850px) {
  .categories {
    display: flex;
    flex-direction: column;
    padding: 15px;
    margin-top: 2rem;
    width: 90%;
    min-width: 90%;
    align-self: center;
    height: 100vh;
    --tw-shadow:0;
    box-shadow: 0 0 rgba(0, 0, 0, 0),0 0 rgba(0, 0, 0, 0),var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
    
  }
}
.price-range {
    display: flex;
    flex-direction: column;
    /* padding: 15px; */
    /* border-radius: 8px; */
    margin-top: 2.5rem;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;

  grid-gap: 10px;

  gap: 10px;

  }
  .price-range h2{
    font-size: 1em;
    text-align: center; 
  }
  .price-range .spans{
    display: flex;
    justify-content: space-between;
    
  }
  .price-range button{
    background-color: var(--main-light-color);
    width: 60%;
    border: 1px solid transparent;
    padding: 10px;
    border-radius: 20px;
    font-weight: bold;
    align-self: center;
  }
 
  @media (max-width:850px) {
    .price-range {
      display: flex;
      flex-direction: column;
      padding: 15px;
      margin-top: 2rem;
      width: 90%;
      min-width: 90%;
    align-self: center;
    --tw-shadow: 0;
    box-shadow: 0 0 rgba(0, 0, 0, 0),0 0 rgba(0, 0, 0, 0),var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
      border: 1px solid transparent;
    }
  }


.filtermobile {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1300;
    background-color: rgb(255, 255, 255);
    width: 70%;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction:column ;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    transition: all 0.2s;
    overflow: auto;
  }
  .filtermobile--open{
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
}
.filtermobile > span{
    padding: 15px 15px 0px 0px;
    color: var(--main-dark-color);
    font-weight: bold;
    cursor: pointer;
    margin-left: auto;
}
.loading-page{
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
vertical-align: middle;
  margin-top: auto;
  grid-gap: 15px;
  gap: 15px;
  color: var(--main-light-color);
}
.loading-page img{
  width: 200px;
  height: auto;
}
.home-container{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 15px;   
}

.cards{
    display: flex;
    justify-content: center;
  
    padding-right: 35px;
}
.home-container .swiper {

  width: 58%;
  height: 370px;
  border-radius: 8px;
  z-index: 1;
  cursor: pointer;
}
.home-container .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-container .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit:cover;
}

.home-container .swiper-pagination-bullet-active {
  background-color: var(--main-light-color) !important;
}

.cards .right-side{
    display: flex;
    flex-direction: column;

    width: 30%;
       justify-content: space-between;

}
.cards .right-side div{
  width: 90%;
  
  border-radius: 10px;
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 0.95em;
  line-height: 2em;
  cursor: pointer;
  text-align: left;

  font-family: "Poppins",sans-serif;
  font-weight: 600;
  --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
  box-shadow: 0 0 rgba(0, 0, 0, 0),0 0 rgba(0, 0, 0, 0),var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);

color: var(--main-dark-color);
transition-duration: 0.1s;

}
.cards .right-side div:first-child{
  background: url(/static/media/best-seller.e77445ae.png);
  background-size:145px 145px;
  background-color: #9b9ddba2;
  background-repeat: no-repeat;
  background-position:right ;
}
.cards .right-side div:last-child{
  background: url(/static/media/luguage.dccb0ea9.png);
  background-size:150px 150px;
  background-color: #cca93563;
  background-repeat: no-repeat;
  background-position:right ;
}
.cards .right-side div h2{
  cursor: pointer;
  width: 60%;
  display: flex;
  justify-content: center;
  text-align: center;
}
.cards .right-side div:hover{

 -webkit-transform: scale(1.02);

         transform: scale(1.02);
}
.cards .right-side div:first-child{
  height: 43%;
 
}
.cards .right-side div:nth-child(2){
  height: 48%;
 
}


.bottom{
    display: flex;
 justify-content: center;
 grid-gap: 20px;
 gap: 20px;
    width: 100%;
    margin-top: 20px;
    
    
}
.categories{
   height:-webkit-fit-content;
   height:-moz-fit-content;
   height:fit-content;
    display: flex;
    flex-direction: column;
    background-color: white;
}
.categories ul{
    list-style: none;
    display: flex;
    flex-direction: column;
}
hr.solid {
    border-top: 3px solid #bbb;}
.new-products-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-gap: 25px;
    gap: 25px;

}
.new-products-container h2:before,
.new-products-container h2:after {
  background-color: rgb(68, 68, 68);
  content: "";
  display: flex;
  height: 1px;
  position: relative;
text-align: center;
  width: 50%;
}

.new-products-container h2:before {
  right: 0.5em;

}

.new-products-container h2:after {
  left:0.5em;
 
}
.new-products-container h2{
  display: flex;
  align-items: center;
  margin: auto;
align-self: center;
font-size:1.8em;
text-transform: uppercase;
font-family: 'Nunito', sans-serif;
width: 80%;
font-weight: 900;
text-align: center;
color: rgb(68, 68, 68);
}
.new-products-container .new-products{
  width: 78%;
  align-self: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr ;
  grid-gap: 15px;
}
.new-products-container .new-products .product-card:hover{
box-shadow: 0;
-webkit-transform: scale(1.01);
        transform: scale(1.01);
}
@media (max-width:870px){      
    .new-products-container{
  width: 100%;
    }
    .new-products-container .new-products{
        display: grid;
        grid-template-columns: 1fr 1fr ;
      grid-gap: 10px;
      padding: 5px;
    
    }
    .new-products-container h2{
      text-align: center;
    }
    .bottom{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      grid-gap: 20px;
      gap: 20px;
      width: 100%;
  }
  .filter{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .filter > span:first-child{
    display: flex;
    grid-gap: 10px;
    gap: 10px;
align-items: center;
font-weight: bold;
font-size: 1.1em;
color: var(--main-light-color);
cursor: pointer;

  }
  }
@media (max-width:850px) {
  .home-container{
    padding-left: 5px;
    padding-right: 5px;
  }
  .cards{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    grid-gap: 30px;
    gap: 30px;
    padding-right: 0;
}
.home-container .swiper {
  width: 90%;
  height: 220px;
  border-radius: 8px;
  z-index: 1;
}
.cards .right-side{
 
  width: 100%;
  grid-gap: 30px;
  gap: 30px;
align-items: center;
}
.cards .right-side div:first-child{
  height: 150px;

}
.cards .right-side div:nth-child(2){
  height: 150px;
}
}
@media (max-width:600px) {
  .new-products-container h2{
font-size: 1.5em;
  }
  .new-products-container .new-products{
    width: 100%;

  }
}
.product-card{
    width: 100%;
    min-width: 200px;
    max-width: 260px;
    border-radius: 8px;
background-color: white;
display: flex;
flex-direction: column;
cursor: pointer;
--tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
box-shadow: 0 0 rgba(0, 0, 0, 0),0 0 rgba(0, 0, 0, 0),var(--tw-shadow);
box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);

}
.product-card:hover{
    transition-duration: 0.1s;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.product-card img{
    width: 100%;
    height: 200px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    z-index: 1;
    object-fit:contain;
}
.img-progress{
    width: 100%;
    height: 200px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    z-index: 1;
    align-items: center;
    justify-content: center;
    color: var(--main-light-color);
}
.product-card .product-info{
    display: flex;
    flex-direction: column;
    padding: 15px;
   
}
.product-card .product-info .category{
    font-size: 14px;
    color:gray;
}
.product-card .product-info .nom{
    font-size: 13px;
    color:var(--main-dark-color);
    height: 4rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
            line-clamp: 3; 
    -webkit-box-orient: vertical;
}

.product-card button{
visibility: visible;
    width: 100%;
    height: 45px;
    z-index: 0;
    background-color: var(--main-light-color);
border-bottom-left-radius: 8px;
border-bottom-right-radius: 8px;
    border: 1px solid transparent;
    color:white;
    font-size: 11px;
    font-weight: bold;
      margin-left: auto;
      margin-right: auto;
   margin-top: auto;
}
.product-card:hover button:hover{
    background-color: #a88924;
}
.product-card .price{
    font-weight: bold;
    font-size: 1.1em;  
}
.price-rating{
    display: flex;
    flex-direction: column;
    margin-top: auto;

}
@media(max-width:549px){
    
    .product-card .product-info .category{
        font-size: 90%; 
    }
  
    .product-card .product-info .nom{
        font-size:93%;
       height: -webkit-max-content;
       height: max-content;
    }
    .product-card .product-info {
        font-size:85%;
        grid-gap: 3px;
        gap: 3px;
    }
    .product-card{
        width: 100%;
    min-width: 100%;
    }

    .product-card button{
    visibility:visible;
    }
    .product-card img{
        height: 150px;
    }
    
}
.shop-sidebar{
    display: flex;
    flex-direction: column;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    grid-gap: 15px;
    gap: 15px;
    background-color: white;
    border-radius: 8px;
       padding: 15px;
      --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
box-shadow: 0 0 rgba(0, 0, 0, 0),0 0 rgba(0, 0, 0, 0),var(--tw-shadow);
box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
width: 22%;
min-width:230px;
}
.verify-email-container{
        display: flex;
        align-items: center;
        justify-content: center;
width: 100%;
height: 100vh;
background-color: var(--main-dark-color);

}
.verify-email-container input{
        padding: 8px;
        height: 45px;
        width: 60%;
        border: 1px solid gray;
        border-radius: 8px;
}
.verify-email-container > div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    
background-color: white;
height: 50%;
width: 50%;
padding: 20px;
border-radius: 8px;
text-align: center;
}
.verify-email-container > form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    
background-color: white;
height: 50%;
width: 50%;
padding: 20px;
border-radius: 8px;
text-align: center;
}
.verify-email-container > div button{
        background-color: var(--main-light-color);
        padding: 10px;
        border: 1px solid transparent;
        border-radius: 8px;
        width: 30%;
        height: 50px;
        font-size: 1.1em;
        font-weight: bold;
        color: white;
}
.verify-email-container > form button{
        background-color: var(--main-light-color);
        padding: 6px;
        border: 1px solid transparent;
        border-radius: 8px;
        width: 30%;
        height: 40px;
        font-size: 1.1em;
        font-weight: bold;
        color: white;
}
.verify-email-container > div button:hover{
      background-color: #aa8c2a;
}
.verify-email-container i{
  
        /* font-size: 2em;
        width: 100px;
        height: 60px; */
        font-size: 4em;
  
        /* line-height: 65px;
        background-color: var(--main-light-color);
        border-radius: 10px; */
        color: var(--main-light-color);
    
}
.verify-email-container h1{
        margin-top: -30px;
}
@media (max-width:600px) {
        .verify-email-container > div{
             width: 100%;
        border-radius: 0;
        } 
        .verify-email-container > form{
                width: 100%;
           border-radius: 0;
           } 
        .verify-email-container > div button{
                background-color: var(--main-light-color);
                width: 50%;
        }
        .verify-email-container h1{
                font-size: 1.7em
        }
}
.shop-container{
    display: flex;
   flex-direction: column;
    padding: 55px 60px 35px 40px;
    grid-gap: 15px;
    gap: 15px;
    justify-content: center;
    
}
.shop-container > div{
    display: flex;
  
    justify-content: center;
    grid-gap: 20px;
    gap: 20px;
       width: 100%;
}

.shop-container .right-side{
    display: flex;
    flex-direction: column;
    width: 63%;

    grid-gap: 25px;

    gap: 25px;
}
.shop-container .right-side > div:first-child{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
    grid-gap: 10px;  
  
}

.shop-container nav {
    text-decoration: none;
    color: gray;
    font-size: 1.2em;
    text-align: center;
}

.shop-container nav a{
text-decoration: none;
color: gray;
}
.shop-container nav a:hover{
    text-decoration: underline;
}
.catego-pricerange{
    width: 100%;
}
.pagination-shop{
    align-self: center;
}
.pas-de-products{
    font-size: 1.2em;
    font-weight: lighter;
    color:gray ;
}
@media (max-width:850px){ 
    .shop-container{
        padding-left:20px ;
        padding-right: 20px;
    } 
    .shop-container > div{
        display: flex;
        flex-direction: column;
    }
 .shop-container .right-side{
    width: 100%;
 }
    .shop-container nav {
       align-self: center;
    }
    .left-side{
        align-self: center;
    }
    .filter{
        align-self: center;
    }
  }
  @media(max-width:600px){
    .shop-container{
        padding: 35px 5px 35px 5px;
    }
    .shop-container .right-side > div:first-child{
        
        grid-template-columns: 1fr 1fr  ;
        grid-gap: 10px;  
      
    }
  }

.payment-container{
    padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.paypal-card{
    background-color: white;
 
    width:45%;
    min-width: 500px;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    border-radius: 8px;
    --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
    box-shadow: 0 0 rgba(0, 0, 0, 0),0 0 rgba(0, 0, 0, 0),var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
}
.payment-body {

   border-top: 1px solid rgba(128, 128, 128, 0.432);
   padding-top: 30px;
}

.payment-body form{
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
}

.payment-body form div {
    display: flex;
    flex-direction: column;
}
.card-input{
    border-radius: 6px;
    padding: 12px;
    border: 1px solid rgba(172, 172, 172, 0.712);
    height: 40px;
    width: 100%;
    background: white;
    
}
 
.payment-body form div input{
    border-radius: 6px;
    padding: 12px;
    border: 1px solid rgba(172, 172, 172, 0.712);
    height: 40px;
    background: white;
    outline: none;
    font-size: 0.9em;
}
.payment-body form div input:focus{
    border: 1px solid var(--main-light-color);
}
.payment-body form div label{
    font-size: 0.87em;
        
        color: var(--main-dark-color);
        font-weight: bold;
}
.paypal{
    display: flex;
    flex-direction: column;
 
    grid-gap: 8px;
 
    gap: 8px;
    padding: 30px;
    border-radius: 8px;
    
}

.p-container{
    color: gray;
    font-size: 0.8em;
    padding: 10px;
}
.radio label{
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    user-select: none;
}
.radio input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.checkmark {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);         
    height: auto;    
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
  }

.radio label:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  .radio label input:checked ~ .checkmark {
    background-color: var(--main-light-color);
  }
  

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  

  .radio label input:checked ~ .checkmark:after {
    display: block;
  }
  
  
  .radio label .checkmark:after {
       top: 6px;
      left: 6px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
  }
.paypal > div:first-child{
    display: flex;
    justify-content: space-between;
}
.paypal-logo{
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}
.paypal .paypal-logo img:first-child{
    width: 25px;
    height: auto;
}
.paypal .paypal-logo img:nth-child(2){
    width: 55px;
    height: auto;
}
.card-payment{
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
    padding: 30px;
}
.card-payment .paypal-logo img{
    width: 30px;
    height: auto;
}
.card-payment > div:first-child{
display: flex;
justify-content: space-between;
}
.payer-button{
    height: 45px;
    border-radius: 8px;
    border: 1px solid transparent;
    padding: 10px;
    background-color: var(--main-light-color);
    font-size: 1em;
    color: white;
    font-weight: bold;
}
.payer-button:hover{
    background-color: #a78823;
}
@media (max-width:600px) {
    .paypal-card{
        background-color: white;
     
        width:100%;
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        border-radius: 8px;
        min-width: 100%;
    }
    .radio label{
        font-size: 16px;
    }
    .payment-container{
        padding: 30px 10px 30px 10px;
    }
    
}
.steptracker{
    display: flex;
    justify-content: center;
align-items: center;
    grid-gap: 5px;
    gap: 5px;
}
.steptracker-mobile{
    font-size: 2.2em;
    text-align: center;
}
.steptracker a{
    text-decoration: none;
    color: rgb(206, 206, 206);
    font-size: 1.4em;
    text-align: center;
}
.steptracker a:hover{
    color: var(--main-dark-color);
}
.steptracker span{
    vertical-align: middle;
    font-size:1.4em;
    display: flex;
    align-items: center;
    color: var(--main-dark-color);
    font-weight: bold;
}
.add-product-container{
    margin-left: 250px;
    padding: 30px;
  
}
.add-product-container form{
    display: flex;
    flex-direction: column;
    grid-gap: 25px;
    gap: 25px;
}
.card1{
    display: flex;
    flex-direction: column;
    grid-gap: 25px;
    gap: 25px;
    background-color: white;
    border-radius: 8px;
    padding: 25px;
    width: 60%;
    --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
    box-shadow: 0 0 rgba(0, 0, 0, 0),0 0 rgba(0, 0, 0, 0),var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
}
.card1 > div:first-child{
display: flex;
flex-direction: column;
}
.card1 > div:last-child{
    display: flex;
    flex-direction: column;
    }
.card1 .second{
 display: flex;
 
}
.card1 .second:nth-child(2){
   grid-gap: 18px;
   gap: 18px; 
   }
.card1 .second > div{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.card1 .second  div div{
    display: flex;
   align-items: center;
   grid-gap: 8px;
   gap: 8px;
}
.card1 .second  div div span{
    font-size: 1.2em;
    cursor: pointer;
    color: var(--main-light-color);
}
.card1 .second  div div span:hover{
    color: green;
}
.card1 input{
    padding: 10px;
    height:35px ;
    border-radius: 8px;
    border: 1px solid rgba(128, 128, 128, 0.281);
}
.card1 textarea{
    border-radius: 5px;
    height: 80px;
    padding: 10px;
    font-size: 1em;
    border: 1px solid rgba(128, 128, 128, 0.281);
    resize: none;
}
.card1 label{
    font-size: 14px;
    
}
.card1 span{
    font-size: 14px;
}
.card1 .second select{
    height: 35px;
  
    border: 1px solid rgba(128, 128, 128, 0.329);
    border-radius: 8px;
    padding: 5px;
    cursor: pointer;
}
.top-stuff{
display: flex;
justify-content:space-evenly;
grid-gap: 20px;
gap: 20px;
}
.card2{
display: flex;
flex-direction: column;
grid-gap: 25px;
gap: 25px;
width: 25%;
background-color: white;
border-radius: 8px;
padding: 25px 25px 35px 25px;
--tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
box-shadow: 0 0 rgba(0, 0, 0, 0),0 0 rgba(0, 0, 0, 0),var(--tw-shadow);
box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
}
.card2 .stock{
    display: flex;
    flex-direction: column;
} 
.card2 .stock input{
    padding: 10px;
    height:35px ;
    border-radius: 8px;
    border: 1px solid rgba(128, 128, 128, 0.281);
}
.card2 .stock label{
    font-size: 14px; 
}
.images-upload{
width: 90%;
display: flex;
flex-direction: column;
grid-gap: 10px;
gap: 10px;
align-self: center;
background-color: white;
border-radius: 8px;
padding: 25px;
--tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
box-shadow: 0 0 rgba(0, 0, 0, 0),0 0 rgba(0, 0, 0, 0),var(--tw-shadow);
box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
}

.images-upload label {
   
    background-color:var(--main-light-color);
    color: var(--main-dark-color);
    padding: 0.5rem;
    font-family: sans-serif;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 1rem;
    
  }
.images-grid{
    background-color: white;
    border-radius: 8px;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
    gap: 15px;
}
.grid-item{
    padding: 15px;
    height: 180px;
    width: 180px;
    border: 1px solid rgba(128, 128, 128, 0.404);
    border-radius: 8px;
}

.grid-item button{
    padding: 5px;
    background-color: white;
    border-radius: 8px;
    border: 1px solid var(--main-light-color);
}
.grid-item button:hover{
    background-color: var(--main-light-color);

}
.submit-product{
   width: 30%;
   padding: 10px;
   justify-self: center;
   align-self: center;
   font-weight: bold;
   font-size: 1.1em;
   background-color: var(--main-light-color);
   border: 1px solid var(--main-light-color);
   border-radius: 8px;
   color:var(--main-dark-color);
}
.submit-product:hover{
  background-color: #a3872b;
}

.show {
    display: flex;
}

.hidden {
    display: none;
}
.color-taille{
    display: flex;
    justify-content:space-evenly;
    grid-gap: 20px;
    gap: 20px;
    
}
.color-taille table{
width: 60%;
    padding-right: 10;
    padding-left: 10;
    border-radius: 10px;
}
.color-taille table th{
    padding-bottom: 15px;
 border-bottom: 1px solid gray;
}
.color-taille table td{
    padding: 0;
    text-align: center;
    padding-top: 15px;
}
.color-taille table td input{
    height: 35px;
    width: 100px;
    border: 1px solid rgba(128, 128, 128, 0.329);
    border-radius: 8px;
    padding: 5px;
    cursor: pointer;
}
.color-taille table  option{
    font-size: 1.2em;
    padding: 10px;
    color: var(--main-dark-color);
}
.top-product-rating{
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}
.add-color{
--tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
box-shadow: 0 0 rgba(0, 0, 0, 0),0 0 rgba(0, 0, 0, 0),var(--tw-shadow);
box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
background-color: white;
width: 25%;
border-radius: 10px;
padding: 15px;
display: flex;
flex-direction: column;
height: -webkit-fit-content;
height: -moz-fit-content;
height: fit-content;
grid-gap: 10px;
gap: 10px;
}
.add-color div:first-child{
    display: flex;
    grid-gap: 5px;
    gap: 5px;
}
.add-color input{
    border-radius: 8px;
    width: 80%;
    outline: none;
    border: 1px solid gray;
    padding: 5px;
}
.add-color button{
    height: 10px;
    padding: 15px;
    border-radius: 8px;
    font-weight: bold;
    border: 1px solid transparent;
   display: flex;
   align-items: center;
   justify-content: center;
   align-self: center;
   background-color: var(--main-light-color);
}
.add-color button:first-child:hover{
    background-color: #9b7c17;
}
.add-color div{
    display: flex;
    justify-content: space-evenly;
}
.add-color div span:nth-child(2){
    cursor:pointer;
}
.add-color div span:nth-child(2):hover{
    color: var(--main-light-color);
}
.sous-category-admin{
    display: flex;
    align-items: center;
    grid-gap: 15px;
    gap: 15px;
}
.add-sous-catego{
display: flex;
grid-gap: 10px;
gap: 10px;
}
.add-sous-catego input{
    height: 35px;
    border: 1px solid rgba(128, 128, 128, 0.329);
    border-radius: 8px;
    padding: 5px;
    cursor: pointer;
}
.add-sous-catego button{
    height: 35px;
    border: 1px solid rgba(128, 128, 128, 0.329);
    border-radius: 8px;
    padding: 5px;
    font-size: 0.8em;
    cursor: pointer;
}
.add-sous-catego button:hover{
    background-color: rgb(148, 144, 144);
}
.admin-dashboard{
    margin-left: 250px;
    padding: 30px;
    width:calc(100% - 250px);
    height: 100%;
    display: flex;
    flex-direction: column;
}
.admin-dashboard .top-cards{
    display: flex;
    grid-gap: 15px;
    gap: 15px;
    font-family: "Poppins",sans-serif;
}
.admin-dashboard .top-cards > div{
    width: calc((100%) / 4);
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: white;
    border-radius: 10px;
}
.admin-dashboard .top-cards i{
    font-size: 1.65em;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 55px;
    background-color: var(--main-light-color);
    border-radius: 10px;
    color: var(--main-dark-color);
}
.card-title{
    font-size: 0.9em;
    font-weight: bold;
}
.admin-dashboard .top-cards .number{
    font-size: 22px;
    font-weight: 600;
    color: rgb(88, 86, 86);
}
#total-commandes i{
    background-color:  rgb(160, 206, 233);
}
#total-users i{
    background-color:rgb(165, 240, 162);
}
#total-products i{
    background-color: #ebd387;
}
#total-revenue i{
    background-color: rgb(238, 171, 171);
}
.bottom-stuff{
    display: flex;
    justify-content: space-between;
    grid-gap: 15px;
    gap: 15px;
    
    height: 100%;
}
.admin-dashboard h3{
    margin-top: 3rem;
}
.products-out-stock {
   
    width: calc((100% / 4) * 3)
}
.products-out-stock table{
}
.products-out-stock table th{
    text-align: left;
    font-size: 0.9em;
}
.products-out-stock table td{
    text-align: left;
    padding-top: 15px;
    font-size: 0.82em;
    color: rgb(66, 66, 66);
    font-weight: 500;
}
.products-out-stock table tbody td:first-child{
 width: 270px;
}
.admin-dashboard .pagination{
    width:100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.paypal-credit-card{
    height: 100%;
    width: calc(100% - ((100% / 4) * 3));
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
}
.paypal-credit-card > div{
    height:200px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: white;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
    box-shadow: 0 0 rgba(0, 0, 0, 0),0 0 rgba(0, 0, 0, 0),var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
}
.paypal-credit-card > div img{
    width: 45px;
    height: auto;
}
.paypal-credit-card > div .number{
    font-size: 22px;
    font-weight: 600;
    color: rgb(88, 86, 86);
}
.sideadmin {
   position: fixed;
    left: 0;
    background-color: rgb(255, 255, 255);
    width: 250px;
    height: 100vh;
    padding-top: 30px;
    border-right: 1px solid #aeb6c1;
  }
  .logo {
    width: 40%;
    height: auto;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
  }
  .logo img {
    max-width: 100%;
    max-height: 100%;
  }
  .sideadmin .elements-container ul {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
    margin-top: 15px;
  }

  .sideadmin .elements-container .element.active {
    border-left: 3px solid var(--main-light-color);
    border-radius: -15%;
  }

  .sideadmin .elements-container .element {
    color: rgb(88, 86, 86);
    display: block;
    text-decoration: none;
    padding-left: 20px;
  }
  .sideadmin .elements-container .element span {
    position: absolute;
    display: inline-block;
    line-height: 50px;
    font-size: 13px;
    font-weight: bold;
  }
  .sideadmin .elements-container .element:hover {
  color: var(--main-light-color);
    background-color: rgba(158, 157, 157, 0.24);
  }


  .sideadmin .elements-container .element .icony {
    display: inline-block;
    text-align: center;
    line-height: 50px;
    font-size: 18px;
    width: 20px;
    margin-right: 1.8rem;
    margin-left: 1rem;
    
  }
  .sideadmin .elements-container ul button{
    width: 70%;
    height: 40px;
    border-radius: 8px;
    border: 1px solid transparent;
    padding: 5px;
    background-color: var(--main-light-color);
   align-self: center;
   font-weight: bold;
   color: black;
   margin-top: 15px;
  }
  .sideadmin .elements-container ul button:hover{
    background-color: #a58827;
  }
  


.service-bagage-container{
    width: 100%;
    height: 100%;
    padding: 30px 30px 30px 30px;
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
    align-items: center;
}
.service-bagage-container a{
    text-decoration: none;
    color: white;
}
.service-bagage-container > div{
    height: 100%;
 
    display: flex;
    justify-content: space-evenly;
    text-align: center;
}
.right-bagages{
   padding-top: 4rem;
    display: flex;
    flex-direction: column;
   align-items: center;
   grid-gap: 25px;
   gap: 25px;
    text-align: center;
}
.right-bagages p{
    font-size: 1.1em;
    font-style: italic;
}
.right-bagages div:last-child{
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 35px;
    gap: 35px;
}
.service-bagage-container > div:first-child button{
    background-color: var(--main-light-color);
    height: 45px;
    font-weight: bold;
    font-size: 1em;
    color: white;
    width: 50%;
    border: 1px solid transparent;
    border-radius: 8px;
}
.service-bagage-container div img{
width: 50%;
height: auto;
position: relative;
top: 0;
}
.tarifs{
    display: flex;
    
    width: 100%;
    justify-content: space-evenly;
    margin-top: 2.5rem;
}
.tarifs div{
    padding: 10px;
  border-radius: 8px;
  --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
  box-shadow: 0 0 rgba(0, 0, 0, 0),0 0 rgba(0, 0, 0, 0),var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
height: 400px;
width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    justify-content: space-evenly;
    font-weight: bold;
    transition-duration: 0.2s;
}
.tarifs div:hover{
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
}
.contact-bagages{
    margin-top: 35px;
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
grid-gap: 30px;
gap: 30px;
}
.contact-bagages button{
    background-color: var(--main-light-color);
    height: 45px;
    font-weight: bold;
    font-size: 1em;
    color: white;
    width: 50%;
    border: 1px solid transparent;
    border-radius: 8px; 
}
@media (max-width:600px) {
    .tarifs{
        flex-direction: column;
        align-items: center;
        grid-gap: 15px;
        gap: 15px;
    }
    .tarifs div{
        width: 90%;
    }
    .tarifs div img{
        width: 70%;
        height: auto;
    }
    .service-bagage-container > div{
       
        flex-direction: column;
   
     
    }
    .service-bagage-container > div img{
       width: 100%;
        height: auto;
      
        
        }
        .service-bagage-container{
            width: 100%;
            height: 100%;
            padding: 30px 30px 30px 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
}

.client-commandes{
    display: flex;
    flex-direction: column;
    padding: 20px;
    height: 100%;
    grid-gap: 1rem;
    gap: 1rem;
}
.client-commandes h1{
        font-size: 1.4em;    
}
.col-title{
    display: flex;
    justify-content: space-between;
    color: black;
    font-weight: bold;
}
.commande{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid rgb(194, 194, 194);
    border-radius: 8px;
    cursor: pointer;
}
.commande:hover{
    background-color: rgba(192, 192, 192, 0.438);
}
.commande span{
    font-size: 0.85em;
    color: rgb(121, 121, 121);
    font-weight: bold;
}
.commande-client-container{
    display: flex;
    flex-direction: column;
    padding: 15px;
    overflow: auto;
}
.commande-client-container .col-title{
    padding: 15px 15px;
    justify-content:space-between;
    border-bottom: 1px solid rgba(189, 189, 189, 0.438);
}

.commande-client-container > div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(189, 189, 189, 0.438);
    padding: 15px 15px;
}
.commande-client-container .image{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100px;
    border: 1px solid rgba(128, 128, 128, 0.473);
    border-radius: 5px;
    padding: 5px;
    text-align: center;
}
.commande-client-container .image img{

   width: 60px;
   height: auto;
   vertical-align: middle;
}
.cmd-container{
    display: flex;
    grid-gap: 5px;
    gap: 5px;
}
.cmd-container > div:first-child{
    display: flex;
    grid-gap: 7px;
    gap: 7px;
    width: 70%;
}
.groupe-info{
    display: flex;
    flex-direction: column;
justify-content: space-evenly;
    font-size: 0.87em;
}
.groupe-info span:first-child{
    width: 270px;
}
.sizo{
    font-weight: bold;
}
.groupe-info span:nth-child(2){
    color: gray;
    font-weight: bold;
}
.groupe-info span:last-child{
    font-weight: bold;
}
@media (max-width:600px) {
    .groupe-info{
        font-size: 0.75em;
    }
    .groupe-info span:first-child{
        width: 160px;
    }
    .cmd-container > div:first-child{
  
        width: 60%;
    }
}

.mon-compte{
    display: flex;
    flex-direction: column;
    padding: 20px;
    height: 100%;
    grid-gap: 2rem;
    gap: 2rem;
}
.mon-compte > div{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    grid-gap: 2rem;
    gap: 2rem;
}
.mon-compte div > div {
    display: flex;
    width: 100%;
}

.mon-compte div div div{
    display: flex;
    flex-direction: column;

}
.mon-compte h1{
    font-size: 1.4em;
}
.mon-compte .email{
    display: flex;
    flex-direction: column;
}
.mon-compte input{
   height: 38px;
   border-radius: 5px;
   border: 1px solid rgba(190, 190, 190, 0.404);
   outline: none;
   padding: 10px;
}
.mon-compte input:focus{
border: 1px solid var(--main-light-color);
}
.mon-compte label{
    font-size: 0.9em;

    color: var(--main-dark-color);
    font-weight: bold;
}
.mon-compte button{
  font-size: 14px;
  height: 40px;
    padding: 5px;
    width: 230px;
    border-radius: 5px;
    background-color: var(--main-light-color);
    border: 1px solid transparent;
    color: white;
    font-weight: bold;
  
}
.mon-compte button:hover{
    background-color: #b3932a;
}
@media (max-width:800px) {
    .mon-compte{
       
        
        height: 100%;
        grid-gap: 20px;
        gap: 20px;
    }
    .mon-compte > div{
    
    grid-gap: 20px;
    
    gap: 20px;
    }
    .mon-compte button{
        width: 100%;
    }
}
.edit-password{
    display: flex;
    flex-direction: column;
    padding: 20px;
    height: 100%;
    grid-gap: 1.5rem;
    gap: 1.5rem;
}
.edit-password h1{
    font-size: 1.4em;
}
.edit-password div{
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-gap: 5px;
    gap: 5px;
}
.edit-password input{
    height: 38px;
    border-radius: 5px;
    border: 1px solid rgba(190, 190, 190, 0.404);
    outline: none;
    padding: 10px;
 }
 .edit-password input:focus{
    border: 1px solid var(--main-light-color);
    }
    .edit-password label{
        font-size: 0.9em;
        color: var(--main-dark-color);
        font-weight: bold;
    }
    .edit-password button{
      font-size: 14px;
      height: 40px;
        padding: 5px;
        width: 230px;
        border-radius: 5px;
        background-color: var(--main-light-color);
        border: 1px solid transparent;
        color: white;
        font-weight: bold;
      
    }
    .edit-password button:hover{
        background-color: #b3932a;
    }
    @media (max-width:800px) {
        .edit-password{
            height: 100%;
            grid-gap: 20px;
            gap: 20px;
        }
        .edit-password button{
             width: 100%;
        }
    }
.account-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height:100%;
    padding:40px 0px 50px 0px;
}
.account-container > div{

    display: flex;
    height:450px;
    width: 65%;
    min-width: 800px;
    background-color: white;
    border-radius: 8px;
    --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
    box-shadow: 0 0 rgba(0, 0, 0, 0),0 0 rgba(0, 0, 0, 0),var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);

}
.account-nav{
    position: relative;
    left: 0;
    width: 320px;
    height: 100%;
    border-right: 1px solid rgba(190, 190, 190, 0.404);
}
.account-nav ul{
    display: flex;
    flex-direction: column;
    list-style: none;
   height: 100%;
   width: 100%;
}
.account-nav ul li{
    border-bottom: 1px solid rgba(168, 168, 168, 0.5);
    display: flex;
    height: 12%;
    align-items: center;
   padding-left: 17px;
   cursor: pointer;
   color: rgb(88, 86, 86);
   text-decoration: none;
   font-size: 14px;
   font-weight: bold;
}
.account-nav ul li div{
  display: flex;
  align-items: center;
  grid-gap: 18px;
  gap: 18px;
}
.account-nav ul li div span{
  font-size: 1.5em;

}
.account-nav ul li:hover{
    background-color: #f9f9f9;
}
.account-nav ul li.active{
    border-left: 3px solid var(--main-light-color);
    color: var(--main-light-color);
}
.account-nav .top-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 22px;
    grid-gap: 10px;
    gap: 10px;
    border-bottom: 1px solid rgba(190, 190, 190, 0.404);
}
.account-nav .top-section .logo {
    width: 100px;
    height: 100px;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    border: 3px solid rgba(190, 190, 190, 0.404);
  }
  .edit-icon{
    position: absolute;
     top: 15px;
     right: 40px;
    font-size: 1.5em;
    color:rgb(88, 86, 86) ;
  }
  .edit-icon:hover{
    color: var(--main-light-color);
  }
  .edit-icon label{
    cursor: pointer;
  }
  .edit-icon label:hover{
color: var(--main-light-color);
  }
  .account-nav .top-section .logo img {
   
    width: 100%;
  height: 100%;
   border-radius: 50%;
   object-fit: cover;
  }
  .username{
    font-weight: bold;
    font-size: 1.1em;
    text-align: center;
    color: var(--main-dark-color);
  }
  .outlet{
    width: 100%;
    overflow: auto;
   
  }
  @media (max-width:800px) {
    .account-container > div{
        flex-direction: column;
        min-width: 100%;
        width: 100%;
        height:100%;
        border-radius: 0px;
    }
    .account-nav{
        position: relative;
        left: 0;
        width: 100%;
    }
    .outlet{
        width: 100%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
      }
      .account-nav ul li{
        height: 50px;
      }
      .edit-icon{
      
         top: 5px;
         right: 36%;
  
      }
  }
.client-route-loading{
    height: 100vh;
    color: var(--main-light-color);
    display: flex;
    justify-content: center;
    align-items: center;
}
.products-container{
    width: auto;
    padding: 30px;
    margin-left: 250px;
}
.products-container > div:first-child{
    display: flex;
    justify-content: space-between;
}
.products-container > div:first-child button{
    height: 40px;
    font-weight: bold;
    padding: 10px;
    background-color: var(--main-light-color);
    border-radius: 10px;
     border: 1px solid transparent;

}

.products-container > div:first-child button:hover{
    color: white;
}
.products-container h1{
    font-family: "poopins",sans-serif;
    margin-top: 1rem;
    margin-bottom: 2rem; ;
    font-size: 1.5em;
    color: var(--main-dark-color);
}
table{
    width: 100%;
    background-color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
   padding: 20px;
    --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
    box-shadow: 0 0 rgba(0, 0, 0, 0),0 0 rgba(0, 0, 0, 0),var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
}

table tbody {
    border-bottom: 1px solid grey;
   
}
table thead tr:last-child{
    border-bottom: 1px solid rgba(128, 128, 128, 0.301);
}

table thead tr th div > span{
    text-align:start;
    font-size: .9em;
}
table th > div{ 
    display: flex;
    flex-direction: column;
    grid-gap: 18px;
    gap: 18px;
}
table th .inputs{
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}
table th > div > input:not(input[type=checkbox]){
    height: 33px;
    border: 1px solid rgba(128, 128, 128, 0.329);
    border-radius: 8px;
    padding: 5px;
    width: 200px;
}
table th .inputs input{
    width: 80px;
    height: 33px;
    border: 1px solid rgba(128, 128, 128, 0.329);
    border-radius: 8px;
    padding: 5px;
}

table th select{
    height: 35px;
    width: 130px;
    border: 1px solid rgba(128, 128, 128, 0.329);
    border-radius: 8px;
    padding: 5px;
    cursor: pointer;

}
table td select{
    height: 35px;
    width: 130px;
    border: 1px solid rgba(128, 128, 128, 0.329);
    border-radius: 8px;
    padding: 5px;
    cursor: pointer;
}
input[type=checkbox] {
    width: 50px; 
    cursor: pointer;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
.edit-selected{
    display: flex;
    margin-bottom: 10px;
    border-left: 1px solid rgba(128, 128, 128, 0.171);
    border-top: 1px solid rgba(128, 128, 128, 0.171); 
  }
.edit-selected div{
    font-size: 0.83em;
    cursor: pointer;
    font-weight: bold;
    padding: 10px;
    border-right: 1px solid rgba(128, 128, 128, 0.171);
    border-bottom: 1px solid rgba(128, 128, 128, 0.171);
}
.edit-selected div:hover{
    color: var(--main-light-color);
}
tbody span{
    font-size: 13px;
}
tbody .titre{
   font-weight: 500;
   cursor: pointer;
}
tbody .titre:hover{
    text-decoration: underline;
}
td{
    padding-top: 25px;
    padding-left: 5px;
}
.pagination{
    display: flex;
    background-color: white;
    --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
    box-shadow: 0 0 rgba(0, 0, 0, 0),0 0 rgba(0, 0, 0, 0),var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    padding: 15px;
    justify-content: space-between;
}
.pagination div{
    display: flex;
    grid-gap: 15px;
    gap: 15px;
    align-items: center;
}
.pagination input{
    width: 50px;
    height: 35px;
    text-align: center;
    border: 1px ;
    border: 1px solid rgba(128, 128, 128, 0.329);
    border-radius: 8px;
}
.pagination span{
    font-size: .9em;
}
.delete-icon{
font-size: 1.2em;
color: var(--main-dark-color);
cursor: pointer;
}
.delete-icon:hover{
    color: var(--main-light-color);
}


.modal-content-catego {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    transition: opacity 5s ;
    line-height: 1.4;
    background: #f1f1f1;
    padding: 20px 28px;
    border-radius: 8px;
    width: 400px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: auto;
}

 .modal-content-catego{
    -webkit-animation-name: example2;
            animation-name: example2;
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
}
@-webkit-keyframes example2 {
    0%   {  opacity:0.2}
    100%  { opacity: 1;}}
@keyframes example2 {
    0%   {  opacity:0.2}
    100%  { opacity: 1;}}
    .modal-content-catego form {
        display: flex;
        flex-direction: column;
        grid-gap: 15px;
        gap: 15px;
    }
.modal-content-catego form > div{
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
}
.category-tit div{
    display: flex;
    justify-content: space-between;
    grid-gap: 15px;
    gap: 15px;
}
.modal-content-catego form div div button{
    background-color: white;
    padding: 5px 10px 5px 10px;
    border: 1px solid var(--main-light-color);
    border-radius: 10px;
    font-weight: bold;
    color: gray;
    font-size: 12px;
}
.modal-content-catego form div div button:hover{
    color: white;
    background-color: var(--main-light-color);
}
.category-tit label{
    font-size: 14px;  
    font-weight: bold;

}
.category-tit input{
    border: 1px solid gray;
    border-radius: 5px;
    height: 32px;
    width: 100%;
    padding: 8px;
    outline: none;
}
.category-tit input:focus{
    border: 1px solid var(--main-light-color);
}
.childs{
    padding-left: 20px;
}
.childs > div{
    display: flex;
    flex-direction: column;
}
.childs div div{
    display: flex;
grid-gap: 115px;
gap: 115px;
 
}
.childs label{
    font-size: 13px;  
    font-weight: bold;
}
.childs input{
    border: 1px solid gray;
    border-radius: 10px;
    height: 30px;
    width: 60%;
    padding: 8px;
}
.childs span{
    font-size: 1.5em;
    color: var(--main-dark-color);
    cursor: pointer;
}
.childs span:hover{
    color: var(--main-light-color);
}
.add-catego{
    width: 30%;
    padding: 10px;
    align-self: center;
    border-radius: 8px;
    border: 1px solid transparent;
    background-color: var(--main-light-color);
    font-weight: bold;
    color: var(--main-dark-color);
}
.add-catego:hover{
    color: white;
}
.commande-container{
    padding: 30px;
    margin-left: 250px;
}
.commande-container h1{
    font-family: "poopins",sans-serif;
    margin-top: 1rem;
    margin-bottom: 2rem; ;
    font-size: 1.5em;
    color: var(--main-dark-color);
}
.commande-container .sous-container{
    display: flex;
   grid-gap: 35px;
   gap: 35px;  
}
.commande-container .sous-container .left-commande{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    grid-gap: 25px;
    gap: 25px;
    background-color: white;
    border-radius: 8px;
    padding: 25px;
    width: 60%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
    box-shadow: 0 0 rgba(0, 0, 0, 0),0 0 rgba(0, 0, 0, 0),var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
}

.produit-commandé{
    display: flex;
   grid-gap: 2.3rem;
   gap: 2.3rem;
    align-items: center;
    
}

.produit-commandé div:first-child{
    height: 100px;
    width: 100px;
    border: 1px solid rgba(128, 128, 128, 0.473);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}
.produit-commandé img{
width: 60px;
height: auto;
}
.produit-commandé > div:nth-child(2){
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
    font-size: 0.93em;
 
}
.quantXprix{
    display: flex;
    font-size: 1em;
    font-weight: bold;
    color: rgba(128, 128, 128, 0.692);
}

.right-commande{
    display: flex;
    flex-direction: column;
   width: 30%;
    grid-gap: 20px;
    gap: 20px;
    
}
.right-commande > div{
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 8px;
    padding: 25px;
 
    --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
    box-shadow: 0 0 rgba(0, 0, 0, 0),0 0 rgba(0, 0, 0, 0),var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
}
.client-info{
    display: flex;
    flex-direction: column;
grid-gap: 12px;
gap: 12px;
}
.sous-container label{
    font-weight: bold;
    font-size: 0.93em;
    color: rgba(0, 0, 0, 0.842);
}
.client-info div{
    display: flex;
    flex-direction: column;
}
.client-titles{
    font-size: 0.87em;
    color: gray;
    margin-bottom: 8px;
}
.client-titles ~ span{
    font-size: 0.83em;
}
.statut-commande button{
width: 50%;
align-self: center;
height: 30px;
background-color: var(--main-light-color);
border: 1px solid transparent;
border-radius: 8px;
}
.statut-commande button:hover{
    background-color: #b3901e;
}
.total{
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
     font-size: 0.93em;
     color: rgb(107, 107, 107);
}
.total span{
    display: flex;
    justify-content: space-between;
}
.total span span{
    color: var(--main-dark-color);
}
.id{
    cursor: pointer;
}
.id:hover{
    text-decoration: underline;
}
@media (max-width: 600px) {

 
}

    .swipers-container{
        --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
        box-shadow: 0 0 rgba(0, 0, 0, 0),0 0 rgba(0, 0, 0, 0),var(--tw-shadow);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
        width:45%;
            background-Color: #fff;
            padding: 20px;
            border-radius: 8px;
            z-index: 0;
    }
.product-images-slider .swiper-slide {
    overflow: hidden;  
    margin-bottom: 15px; 
    position: relative;
    padding-top: 100%;
   
}
    .product-images-slider .swiper-slide img{
        width: 60%;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);         
        height: auto;    
        
    }
   .product-images-slider .swiper-button-prev {
        left: 0;
        color: var(--main-light-color);
      
    }
    .product-images-slider .swiper-button-next {
        right: 0;
        color: var(--main-light-color);
       
    }
      
    .product-images-slider-thumbs .swiper-slide {
        cursor: pointer;
        border-radius: 5px;
        border: 1px solid #dddbdb;
        text-align: center;
        height: 150px;;
    }

        .product-images-slider-thumbs .swiper-slide-thumb-active {
            border-color: var(--main-light-color);
            border-radius: 5px;
        }
        .product-images-slider-thumbs-wrapper{
            width: 100%;
           height: 100px;
            overflow: hidden;
            /* padding: 8px; */
        
        }
        .product-images-slider-thumbs img{
            display: flex;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);     
            width: 60%;
            height: auto;
        }


    @media (max-width:850px) {
        .swipers-container{
            width: 100%;
        }
    }
.show {
    display: flex;
}

.hidden {
    display: none;
}

.product-contain{
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    padding: 30px 100px 70px 100px;
    overflow-x: hidden;
}
.product-information{
    display: flex;
    grid-gap: 40px;
    gap: 40px;
 
}
.ketba {
    height: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 25px;
    gap: 25px;
    align-items: flex-start;
    font-family: "poopins",sans-serif;
    padding: 20px;
        }
        .ketba h1{
            font-size: 1.8em;
            font-weight: 750;
            width: 95%;
            font-style: normal;
            text-align: start;
          color: #504e4e;
            font-family: "poopins",sans-serif;
            line-height: 1.4em;
        }
        .ketba h2{
            font-size: 1.75em;
            font-weight: 700;
        }
        hr.solid4 {
            border-top:2px solid rgba(187, 187, 187, 0.644);
            width: 90%;
             align-self: flex-start;
             margin-bottom: 10px;
        }
        .ketba select{
            height: 45px;
            padding: 10px;
            width: 130px;
            border: 1px solid rgba(128, 128, 128, 0.384);
            border-radius: 8px;
            --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
        box-shadow: 0 0 rgba(0, 0, 0, 0),0 0 rgba(0, 0, 0, 0),var(--tw-shadow);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
        }

        .ajouter-au-panier{
           display: block;
           height: 45px;
            padding: 10px;
            width: 275px;
           border: 1px solid transparent;
           border-radius: 8px;
           background-color: var(--main-light-color);
           font-size: 1.2em;
           font-weight: bold;
           color: var(--main-dark-color);
        }
        .ajouter-au-panier:hover{
            background-color: rgb(180, 138, 21);
        }
.description-product{
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    width: 90%;
}
.description-product h2{
 font-size: 1.8em;
 font-weight: 500;
}
.description-product p{
    width: 80%;
    line-height: 2rem;
}
.avis-container{
    
display: flex;
justify-content: space-between;
}
.avis{
    display: flex;
    flex-direction: column;
    grid-gap: 25px;
    gap: 25px;
    width: 50%;
    height: 100%;
}
.avi{
    display: flex;
    grid-gap: 15px;
    gap: 15px;
    align-items: center;
    font-style: italic;
}
.avi img{
    width: 70px;
    height: 70px;
    border-radius: 50%;
}
.avi div{
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}
.create-avi{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
    box-shadow: 0 0 rgba(0, 0, 0, 0),0 0 rgba(0, 0, 0, 0),var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
        padding: 25px 30px 55px 30px;
        width: 40%;
      height: 450px;
        border-radius: 8px;
}
.create-avi h3{
    font-size: 1.2em;
    font-weight: 700;
    font-style: normal;
  color: #504e4e;
    font-family: "poopins",sans-serif;
  
}
.create-avi h4{
    font-size: 0.9em;
}

.create-avi button{
    width: 40%;
    padding:10px ;
    height: 45px;
    background-color: white;
    border: 1px solid rgba(128, 128, 128, 0.589);
    border-radius: 5px;
    font-weight: bold;
    font-size: 1em;
    background-color: #b18f20;
    color: white;
}
.create-avi button:hover{
    background-color: #967a21;
}
.create-avi textarea{
    resize: none;
    border: 1px solid rgba(128, 128, 128, 0.589);
}
.qty-size{
    display: flex;
    grid-gap: 15px;
    gap: 15px;
}
.qty-input{
    /* height: 120px;
    min-width: 380px; */
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid rgba(128, 128, 128, 0.384);
    --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
    box-shadow: 0 0 rgba(0, 0, 0, 0),0 0 rgba(0, 0, 0, 0),var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
        
}
.qty-input span{
    width: 100%;
    text-align: center;
    /* font-size: 55px; */
    font-weight: 600;
}
.qty-input span.num{
    /* font-size: 50px; */
    border-right: 2px solid rgba(0,0,0,0.2);
    border-left: 2px solid rgba(0,0,0,0.2);
}
.qty-input span:not(.num):hover{
color: var(--main-light-color);
cursor: pointer;
}
.product-contain nav{
    color: gray;
font-size: 1.3em;
}
.product-contain nav a{
text-decoration: none;
color: gray;
}
.product-contain nav a:hover{
    text-decoration: underline;
}
.avis-title{
    font-size: 1.2em;
    font-weight: 700;
    font-style: normal;
  color: #504e4e;
    font-family: "poopins",sans-serif;
}
.aditionals{
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
}
.aditionals div span:first-child{
    font-size: 0.95em;
}
.aditionals div:first-child span:not(:first-child){
    font-size: 0.95em;
}
.aditionals .icons{
    display: flex;
    align-items:center;
    grid-gap: 5px;
    gap: 5px;
}
.icons span:nth-child(2){
    color: blue;
}
.icons span:nth-child(3){
    color:deepskyblue;
}
.icons span:nth-child(4){
    color:rgb(11, 151, 11);
}
.icons span:nth-child(5){
    color:red;
}
.aditionals .icons span:not(:first-child){
    font-size: 1.5em;
    cursor: pointer;
}
.qty-size > div:last-child{
    display: flex;
    grid-gap: 15px;
    gap: 15px;
}
@media (max-width:850px) {

        .product-information{
            display: flex;
            flex-direction: column;
            grid-gap: 40px;
            gap: 40px;
         
        }
        .ketba {
            height: 100%;
            display: flex;
            flex-direction: column;
            grid-gap: 25px;
            gap: 25px;
            align-items:center;
            font-family: "poopins",sans-serif;
            padding: 20px;
                }
                .ketba h1{
                    text-align: center;
                }
                hr.solid4 {
               
                     align-self: center;
                   
                }
                .description-product p{
                    width: 100%;
                    line-height: 2rem;
                }
                .avis-container{
    
                    display: flex;
                    flex-direction: column;
                  grid-gap: 25px;
                  gap: 25px;
                    }
                    .avis{
                       width: 100%;
                     
                    }
    .create-avi{    
        width: 100%;
    }
    .create-avi button{
        width: 60%;
        padding: 5px;
    }
    .avi img{
        width: 60px;
        height: auto;
    }
    .product-contain{
        padding: 30px 80px 70px 80px;   
    }
}
@media (max-width:549px) {
    .ketba{
        width: 100%;
    }
    .avi{
        align-items: center;
    }
    .avi img{
        width: 50px;
        height: 50px;
    }
    .product-contain{  
        padding: 30px 10px 70px 10px;
    }  
    .product-contain nav{
        color: gray;
    font-size: 1.2em;
text-align: center;
    }
    .product-contain nav a{
    text-decoration: none;
    color: gray;
    }
    .create-avi button{
    font-size: 0.9em;
    }
    .qty-size{
        display: flex;
        flex-direction: column;
     width: 70%;
    }
    .ajouter-au-panier{
        width:70% ;
    }
    .qty-size > div:last-child{
        display: flex;
      flex-direction: column;
        grid-gap: 15px;
        gap: 15px;
    }
.qty-input{
    width:100%;
    height: 40px;
    align-self: center;
}
.ketba select{
    width: 100%;
    align-self: center;
}
}
.show {
    display: flex;
}

.hidden {
    display: none;
}
.step1-container{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 30px;
grid-gap: 15px;
gap: 15px;
overflow-x: hidden;
}
.step1-container table{
    width: 70%;
    min-width: 750px;
    margin-top: 2rem;
    border-radius: 10px;
    --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
    box-shadow: 0 0 rgba(0, 0, 0, 0),0 0 rgba(0, 0, 0, 0),var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
}
.step1-container table tr{
    border-top: 5px solid gray;
    border-bottom: 5px solid gray;
}
.step1-container table thead tr {
    text-align:center;
    color:var(--main-light-color) ;
    font-size: 0.9em
}
.step1-container table tbody tr td:not(.product-name,.sous-tot){
    text-align:center;
}
.step1-container table tbody tr td span:not(.product-name span){
    font-size: 0.95em;
    font-weight: bold;
}

.product-small-img{
    height: 100px;
    width: 100px;
    border: 1px solid rgba(128, 128, 128, 0.473);
    border-radius: 5px;
    padding: 5px;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
   
    
}
.product-small-img img{
    display: flex;
    align-items: center;
   width: 60px;
   height: auto;
   text-align: center;
   margin: auto;
   vertical-align: middle;
   
}
.step1-container .qty-input{
    margin: auto;
    height: 40px;
}
table thead tr:nth-child(2){
    text-align: center; 
}
hr.solid6 {
    border-top:1px solid #d3ad327e;
     text-align: center;
    margin-left: auto;
    margin-right: auto;
}
hr.solid7 {
    border-top:1px solid rgba(128, 128, 128, 0.445);
     text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.step1-container button{
    padding: 14px;
    background-color: var(--main-light-color);
    border-radius: 8px;
    border: 1px solid transparent;
    font-size: 1em;
    font-weight: 500;
    color: var(--main-dark-color);
}
.step1-container > div:last-child{
    width: 70%;
    display: flex;
  justify-content: end;
}
.step1-container button:hover{
   background-color:  #a8881f;
}
.product-name{
  font-weight: 600;
  font-size: 0.95em;
color: gray;
vertical-align: middle;
width: 250px;
}
.sous-tot{
    text-align: left;
    font-size: 0.92em;
}
.sous-tot span:first-child{
    color: gray;
   
}
.product-name div{
display: flex;
flex-direction: column;
}
.product-name div .prix{
    color: black;
    font-weight: bold;
}
@media (max-width:600px) {
    .step1-container table{
        width: 100%;
        min-width: 100%;
        margin-top: 2rem;
        border-radius: 0px;
    }
    .product-small-img{
        height: 80px;
        width: 80px;
        border: 1px solid rgba(128, 128, 128, 0.473);
        border-radius: 5px;
        padding: 5px;
        margin: auto;
    }
    .product-small-img img{
        width: 45px;
        height: auto;
     }
   .step1-container .qty-input{

   
        width: 90px;
    }
    .step1-container{
    
        padding: 30px 0px 30px 0px;
       
        }
        .step1-container > div:last-child{
            width: 70%;
            display: flex;
          justify-content: center;
        }
        .product-name span{ 
            font-size: 0.8em;
            font-weight: 400;
          }
          .step1-container table thead tr {
            text-align:left;
        }
}


.step2-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    grid-gap: 15px;
    gap: 15px;
    overflow-x: hidden;
    }
    .details-commande{
        display: flex;
        justify-content:center;
        grid-gap: 20px;
        gap: 20px;
        margin-top: 2rem;
    }
    .details-commande h3{
      color:var(--main-dark-color);
    }
    .details-commande .left-card{
        display: flex;
        flex-direction: column;
        padding: 25px;
        width: 50%;
        --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
        box-shadow: 0 0 rgba(0, 0, 0, 0),0 0 rgba(0, 0, 0, 0),var(--tw-shadow);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
        background-color: white;
        border-radius: 8px;    
        min-width: 550px;
        grid-gap: 10px;
        gap: 10px;
    }
    .details-commande .left-card label{
        font-size: 0.87em;
      
        color: var(--main-dark-color);
        font-weight: bold;
    }
    .nom-prenom{
       display: flex;
       grid-gap: 15px;
       gap: 15px;
       justify-content: space-between;     
    }  
    .nom-prenom div{
        display: flex;
        flex-direction: column;
        width: 100%;  
        grid-gap: 5px;  
        gap: 5px;
    }
    .adresse{
        display: flex;
        flex-direction: column;
        grid-gap: 5px;
        gap: 5px;
    }
    .pays-region{
        display: flex;
        grid-gap: 15px;
        gap: 15px;
        justify-content: space-between;
    }
.pays-region div{
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-gap: 5px;
    gap: 5px;
}
.zip-code{
    display: flex;
    flex-direction: column;
}
.Telephone{
    display: flex;
    flex-direction: column;
}
.E-mail{
    display: flex;
    flex-direction: column;
}
.details-commande .left-card input{
    height: 40px;
    border-radius: 5px;
    border: 1px solid rgba(172, 172, 172, 0.712);
    padding: 5px;
    font-size: 1em;
}
.details-commande .left-card input:focus{
    border: 2px solid var(--main-light-color);
    outline: none;
}
.region-input,.country-input{
    height:40px ;
    border-radius: 5px;
    border: 1px solid rgba(172, 172, 172, 0.712) ;
    padding: 5px;
    font-size: 0.9em;
}

    .details-commande .right-card{
        display: flex;
        flex-direction: column;
        padding: 25px;
        --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
        box-shadow: 0 0 rgba(0, 0, 0, 0),0 0 rgba(0, 0, 0, 0),var(--tw-shadow);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
        background-color: white;
        border-radius: 8px;  
        width: 33%;
        min-width: 300px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .details-commande .right-card table{
        background-color: transparent;
        border: 1px solid transparent;
        box-shadow: none;
     padding: 10px 5px 10px 5px;
     width: 100%;
     
    }
    .details-commande .right-card table thead
    th{
    text-align: left;
    font-size: 13px;
    color: #d3ad32;
    padding-bottom: 10px;
    border-bottom: 2px solid var(--main-light-color);
}
.details-commande .right-card td:not(.price-last,.price-last~td){
    border-bottom: 1px solid rgba(128, 128, 128, 0.438);
    padding-bottom: 10px;
    text-align: left;
 font-size:13px ;
 padding-top: 15px;
}
.item-titre{
    width: 72%;
}
.price{
    text-align: left;
    border-bottom: 1px solid gray;
    color: black;
    font-size: 15px;
   
}
.price2 span{
    font-size: 14px;
    color: black;
    font-weight: bold;
}
.price span{
    font-size: 14px;
    color: black;
    font-weight: bold;
}
.price td:first-child,.price-last{
    font-weight: bold;
    color: gray;
}
.right-card button{
    padding: 10px;
    background-color: var(--main-light-color);
    border-radius: 8px;
    border:1px solid transparent;
    font-weight: bold;
    color: white;
    font-size: 0.95em;

}
.right-card button:hover{
    color: var(--main-dark-color);
}
@media (max-width:900px) {
    .details-commande{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .details-commande .left-card{
        display: flex;
        flex-direction: column;
        padding: 25px;
        width: 90%;
   
    }
    .details-commande .right-card{
        width: 90%;
    }  
}
@media (max-width:600px) {
    .nom-prenom{
        display: flex;
        flex-direction: column;
     }  
    .details-commande .left-card{ 
        min-width: 100%;   
        width: 100%;
    }
    .step2-container{
        padding: 10px;
        }
        .details-commande .right-card{ 
            min-width: 100%;   
            width: 100%;
        }
        .region-input,.country-input{
         
            font-size: 0.8em;
        }
}
.step4-container{
 display: flex;
 flex-direction: column;

text-align: center;
padding: 30px;
height: 80vh;
grid-gap: 15px;
gap: 15px;
overflow-x: hidden;
}

.span-payment{

    font-size: 1.3em;
    font-weight: 500;
   
    display: flex;
    flex-direction: column;
}
.sous-step4{
  
   height: 60%;justify-content: space-evenly;
   margin-top: 2rem;
   display: flex;
   flex-direction: column;
align-items: center;
      align-self: center;
  justify-content: space-between;
    background-color: white;
    padding: 35px;
    border-radius: 8px;
    --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
    box-shadow: 0 0 rgba(0, 0, 0, 0),0 0 rgba(0, 0, 0, 0),var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
}
.sous-step4 button{
    width: 100%;
    height: 40px;
    padding: 5px;
    border-radius: 8px;
    background-color: var(--main-light-color);
    font-weight: 500;
    border: 1px solid transparent;
    font-size: 1.2em;
}
.sous-step4 button:hover{
    background-color: #bd9a2a;
}

.contact-container{
width: 100%;
height: 100%;
padding: 8rem 30px 30px 30px;
display: flex;
flex-direction: column;
}
.contact-container .bg{
 z-index: -1;
 background-image: url(/static/media/bg-talya.aa0ca73b.png);
background-position:center center;
-webkit-transition: opacity 1s;
background-repeat: no-repeat;
background-size: cover;
height: 100%;
left: 0;
position: absolute;
top: 0;
width: 100%;
}
.title-contact{
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 25px;
    gap: 25px;
    
}
.title-contact button{
    width: 220px;
    height: 42px;
    background-color: var(--main-light-color);
    padding: 5px;
    border-radius: 5px;
    border: 1px solid transparent;
    font-size: 1.1em;
    font-weight: bold;
    color: var(--main-dark-color);
}
.title-contact button:hover{
   background-color: #ad8c1d;
}

.email-phone{
    display: flex;
    justify-content: center;
    grid-gap: 40px;
    gap: 40px;
    margin-top: 5rem;
    
}
.email-phone > div{
    width: 36%;
    height: 350px;
    background-color: #fff;
    border-radius: 8px;
    padding: 40px 20px 10px 20px ;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 0.7rem;
    gap: 0.7rem;
    text-align: center;
    --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
    box-shadow: 0 0 rgba(0, 0, 0, 0),0 0 rgba(0, 0, 0, 0),var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
}
.email-phone h2{
font-size: 1.2em;
font-weight: 700;
}
.cercle-icon{
    font-size: 3em;
    color: var(--main-dark-color);
}
.email-phone  div span:first-child{
color: gray;
font-size: 0.5em;

}
.email-phone span:last-child{
    color: var(--main-light-color);
    font-weight: bold;
    font-size: 1.1em;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


input[type=number] {
  -moz-appearance: textfield;
}
.message{
    display: flex;
    flex-direction: column;
    width: calc(72% + 40px);
    grid-gap: 25px;
    gap: 25px;
    background-color: white;
    border-radius: 8px;
    padding: 35px;
    margin-top: 2rem;
    margin-left: auto;
  margin-right: auto;
  --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
  box-shadow: 0 0 rgba(0, 0, 0, 0),0 0 rgba(0, 0, 0, 0),var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
}
.message form{
    display: flex;
    flex-direction: column;
    grid-gap: 25px;
    gap: 25px;
}
.message h2{

    font-size: 1.1em;

}
.message div{
    display: flex;
    grid-gap: 15px;
    gap: 15px;
}
.message input{
    width: 50%;
    height: 55px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid rgba(172, 172, 172, 0.712);
    font-size: 1em;
    font-size: 0.9em;
    outline: none;
}
.message input:focus{
    border: 1px solid var(--main-light-color);
}
.message textarea{
    height: 140px;
    resize: vertical;
    border-radius: 8px;
    border: 1px solid rgba(172, 172, 172, 0.712);
    outline: none;
}
.message textarea:focus{
    border: 1px solid var(--main-light-color);
}
.message button{
    width: 220px;
    height: 42px;
    background-color: var(--main-light-color);
    padding: 5px;
    border-radius: 5px;
    border: 1px solid transparent;
    font-size: 1.05em;
    color: var(--main-dark-color);
    align-self: center;
}

.message button:hover{
background-color: #ad8c1d;}
@media (max-width:600px) {
    .email-phone{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .email-phone > div{
        width: 90%;
    }
    .message {
        width: 90%;
        padding: 20px;
    }
    .message div{
        display: flex;
        flex-direction: column;
    }
    .message input{
        width: 100%;
    }
    .contact-container{
        padding: 8rem 10px 30px 10px;
  
        }
        .contact-container .bg{
            height: 80%;
        }
}
.admin-account{
    margin-left: 250px;
    height: 100%;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

}
.admin-account > div{
    background-color: white;
    padding: 20px;
    width: 70%;
    border-radius: 8px;
    --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
    box-shadow: 0 0 rgba(0, 0, 0, 0),0 0 rgba(0, 0, 0, 0),var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
}
.admin-account h1{
    font-size: 1.1em;
}
