@import url('https://fonts.googleapis.com/css2?family=Scada&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mandali&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@900&display=swap');
.loading-page{
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
vertical-align: middle;
  margin-top: auto;
  gap: 15px;
  color: var(--main-light-color);
}
.loading-page img{
  width: 200px;
  height: auto;
}
.home-container{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    gap: 20px;
    margin-bottom: 15px;   
}

.cards{
    display: flex;
    justify-content: center;
  
    padding-right: 35px;
}
.home-container .swiper {

  width: 58%;
  height: 370px;
  border-radius: 8px;
  z-index: 1;
  cursor: pointer;
}
.home-container .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.home-container .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit:cover;
}

.home-container .swiper-pagination-bullet-active {
  background-color: var(--main-light-color) !important;
}

.cards .right-side{
    display: flex;
    flex-direction: column;

    width: 30%;
       justify-content: space-between;

}
.cards .right-side div{
  width: 90%;
  
  border-radius: 10px;
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 0.95em;
  line-height: 2em;
  cursor: pointer;
  text-align: left;

  font-family: "Poppins",sans-serif;
  font-weight: 600;
  --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);

color: var(--main-dark-color);
transition-duration: 0.1s;

}
.cards .right-side div:first-child{
  background: url('../../../assets/best-seller.png');
  background-size:145px 145px;
  background-color: #9b9ddba2;
  background-repeat: no-repeat;
  background-position:right ;
}
.cards .right-side div:last-child{
  background: url('../../../assets/luguage.png');
  background-size:150px 150px;
  background-color: #cca93563;
  background-repeat: no-repeat;
  background-position:right ;
}
.cards .right-side div h2{
  cursor: pointer;
  width: 60%;
  display: flex;
  justify-content: center;
  text-align: center;
}
.cards .right-side div:hover{

 transform: scale(1.02);
}
.cards .right-side div:first-child{
  height: 43%;
 
}
.cards .right-side div:nth-child(2){
  height: 48%;
 
}


.bottom{
    display: flex;
 justify-content: center;
 gap: 20px;
    width: 100%;
    margin-top: 20px;
    
    
}
.categories{
   height:fit-content;
    display: flex;
    flex-direction: column;
    background-color: white;
}
.categories ul{
    list-style: none;
    display: flex;
    flex-direction: column;
}
hr.solid {
    border-top: 3px solid #bbb;}
.new-products-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 25px;

}
.new-products-container h2:before,
.new-products-container h2:after {
  background-color: rgb(68, 68, 68);
  content: "";
  display: flex;
  height: 1px;
  position: relative;
text-align: center;
  width: 50%;
}

.new-products-container h2:before {
  right: 0.5em;

}

.new-products-container h2:after {
  left:0.5em;
 
}
.new-products-container h2{
  display: flex;
  align-items: center;
  margin: auto;
align-self: center;
font-size:1.8em;
text-transform: uppercase;
font-family: 'Nunito', sans-serif;
width: 80%;
font-weight: 900;
text-align: center;
color: rgb(68, 68, 68);
}
.new-products-container .new-products{
  width: 78%;
  align-self: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr ;
  grid-gap: 15px;
}
.new-products-container .new-products .product-card:hover{
box-shadow: 0;
transform: scale(1.01);
}
@media (max-width:870px){      
    .new-products-container{
  width: 100%;
    }
    .new-products-container .new-products{
        display: grid;
        grid-template-columns: 1fr 1fr ;
      grid-gap: 10px;
      padding: 5px;
    
    }
    .new-products-container h2{
      text-align: center;
    }
    .bottom{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 20px;
      width: 100%;
  }
  .filter{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .filter > span:first-child{
    display: flex;
    gap: 10px;
align-items: center;
font-weight: bold;
font-size: 1.1em;
color: var(--main-light-color);
cursor: pointer;

  }
  }
@media (max-width:850px) {
  .home-container{
    padding-left: 5px;
    padding-right: 5px;
  }
  .cards{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 30px;
    padding-right: 0;
}
.home-container .swiper {
  width: 90%;
  height: 220px;
  border-radius: 8px;
  z-index: 1;
}
.cards .right-side{
 
  width: 100%;
  gap: 30px;
align-items: center;
}
.cards .right-side div:first-child{
  height: 150px;

}
.cards .right-side div:nth-child(2){
  height: 150px;
}
}
@media (max-width:600px) {
  .new-products-container h2{
font-size: 1.5em;
  }
  .new-products-container .new-products{
    width: 100%;

  }
}