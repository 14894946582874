@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');
html{scroll-behavior: smooth}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root{
  --main-dark-color:#0c1013;
  --main-light-color:#d3ad32;
}
body {
  font-family: "Poppins", sans-serif;
  background-color: #f9f9f9;

}
body.active-modal {
  overflow-y: hidden;
}

