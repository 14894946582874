.product-card{
    width: 100%;
    min-width: 200px;
    max-width: 260px;
    border-radius: 8px;
background-color: white;
display: flex;
flex-direction: column;
cursor: pointer;
--tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);

}
.product-card:hover{
    transition-duration: 0.1s;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.product-card img{
    width: 100%;
    height: 200px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    z-index: 1;
    object-fit:contain;
}
.img-progress{
    width: 100%;
    height: 200px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    z-index: 1;
    align-items: center;
    justify-content: center;
    color: var(--main-light-color);
}
.product-card .product-info{
    display: flex;
    flex-direction: column;
    padding: 15px;
   
}
.product-card .product-info .category{
    font-size: 14px;
    color:gray;
}
.product-card .product-info .nom{
    font-size: 13px;
    color:var(--main-dark-color);
    height: 4rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
            line-clamp: 3; 
    -webkit-box-orient: vertical;
}

.product-card button{
visibility: visible;
    width: 100%;
    height: 45px;
    z-index: 0;
    background-color: var(--main-light-color);
border-bottom-left-radius: 8px;
border-bottom-right-radius: 8px;
    border: 1px solid transparent;
    color:white;
    font-size: 11px;
    font-weight: bold;
      margin-left: auto;
      margin-right: auto;
   margin-top: auto;
}
.product-card:hover button:hover{
    background-color: #a88924;
}
.product-card .price{
    font-weight: bold;
    font-size: 1.1em;  
}
.price-rating{
    display: flex;
    flex-direction: column;
    margin-top: auto;

}
@media(max-width:549px){
    
    .product-card .product-info .category{
        font-size: 90%; 
    }
  
    .product-card .product-info .nom{
        font-size:93%;
       height: max-content;
    }
    .product-card .product-info {
        font-size:85%;
        gap: 3px;
    }
    .product-card{
        width: 100%;
    min-width: 100%;
    }

    .product-card button{
    visibility:visible;
    }
    .product-card img{
        height: 150px;
    }
    
}