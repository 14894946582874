.user-avatar {
 display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    color: var(--main-dark-color);
    }
.user-avatar span:first-child{
    font-weight: 600;
    font-size: 1em;
    color: var(--main-dark-color);
    font-family: "poopins",sans-serif;
}
.user-avatar span:last-child{
    font-size: 1.4em;
    color:var(--main-dark-color);
}
.avatar-icon{
    width: 40px;
    height: 40px;
    overflow: hidden;   
     margin-left: auto;
    margin-right: auto;
  
}
.avatar-icon img{
    width: 100%;
    height: 100%;
     border-radius: 100%;
     object-fit: cover;
}
.menu-user-avatar{
    position: absolute;
    margin: 10px 0;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 170px;
    
    display: none;
}
.menu-user-avatar.active{
    display: block;
}
.menu-user-avatar div{
    padding: 6px 14px 6px 14px ;
    display: flex;
    align-items: center;
    justify-content: space-between; 
    cursor: pointer;
}
.menu-user-avatar div span:first-child{
    font-size: 0.8em;
    font-weight: 600;
    color: gray;
}
.menu-user-avatar div span:nth-child(2){
    font-size: 1.3em;
    vertical-align: middle;
    color: gray;
}
.menu-user-avatar div:first-child{
    border-bottom: 1px solid rgba(172, 172, 172, 0.452);
}
.menu-user-avatar div:hover{
    background-color: rgba(196, 196, 196, 0.315);
}