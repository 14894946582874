.steptracker{
    display: flex;
    justify-content: center;
align-items: center;
    gap: 5px;
}
.steptracker-mobile{
    font-size: 2.2em;
    text-align: center;
}
.steptracker a{
    text-decoration: none;
    color: rgb(206, 206, 206);
    font-size: 1.4em;
    text-align: center;
}
.steptracker a:hover{
    color: var(--main-dark-color);
}
.steptracker span{
    vertical-align: middle;
    font-size:1.4em;
    display: flex;
    align-items: center;
    color: var(--main-dark-color);
    font-weight: bold;
}