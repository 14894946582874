.mon-compte{
    display: flex;
    flex-direction: column;
    padding: 20px;
    height: 100%;
    gap: 2rem;
}
.mon-compte > div{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 2rem;
}
.mon-compte div > div {
    display: flex;
    width: 100%;
}

.mon-compte div div div{
    display: flex;
    flex-direction: column;

}
.mon-compte h1{
    font-size: 1.4em;
}
.mon-compte .email{
    display: flex;
    flex-direction: column;
}
.mon-compte input{
   height: 38px;
   border-radius: 5px;
   border: 1px solid rgba(190, 190, 190, 0.404);
   outline: none;
   padding: 10px;
}
.mon-compte input:focus{
border: 1px solid var(--main-light-color);
}
.mon-compte label{
    font-size: 0.9em;

    color: var(--main-dark-color);
    font-weight: bold;
}
.mon-compte button{
  font-size: 14px;
  height: 40px;
    padding: 5px;
    width: 230px;
    border-radius: 5px;
    background-color: var(--main-light-color);
    border: 1px solid transparent;
    color: white;
    font-weight: bold;
  
}
.mon-compte button:hover{
    background-color: #b3932a;
}
@media (max-width:800px) {
    .mon-compte{
       
        
        height: 100%;
        gap: 20px;
    }
    .mon-compte > div{
    
    gap: 20px;
    }
    .mon-compte button{
        width: 100%;
    }
}