.edit-password{
    display: flex;
    flex-direction: column;
    padding: 20px;
    height: 100%;
    gap: 1.5rem;
}
.edit-password h1{
    font-size: 1.4em;
}
.edit-password div{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
}
.edit-password input{
    height: 38px;
    border-radius: 5px;
    border: 1px solid rgba(190, 190, 190, 0.404);
    outline: none;
    padding: 10px;
 }
 .edit-password input:focus{
    border: 1px solid var(--main-light-color);
    }
    .edit-password label{
        font-size: 0.9em;
        color: var(--main-dark-color);
        font-weight: bold;
    }
    .edit-password button{
      font-size: 14px;
      height: 40px;
        padding: 5px;
        width: 230px;
        border-radius: 5px;
        background-color: var(--main-light-color);
        border: 1px solid transparent;
        color: white;
        font-weight: bold;
      
    }
    .edit-password button:hover{
        background-color: #b3932a;
    }
    @media (max-width:800px) {
        .edit-password{
            height: 100%;
            gap: 20px;
        }
        .edit-password button{
             width: 100%;
        }
    }