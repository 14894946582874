.verify-email-container{
        display: flex;
        align-items: center;
        justify-content: center;
width: 100%;
height: 100vh;
background-color: var(--main-dark-color);

}
.verify-email-container input{
        padding: 8px;
        height: 45px;
        width: 60%;
        border: 1px solid gray;
        border-radius: 8px;
}
.verify-email-container > div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    
background-color: white;
height: 50%;
width: 50%;
padding: 20px;
border-radius: 8px;
text-align: center;
}
.verify-email-container > form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    
background-color: white;
height: 50%;
width: 50%;
padding: 20px;
border-radius: 8px;
text-align: center;
}
.verify-email-container > div button{
        background-color: var(--main-light-color);
        padding: 10px;
        border: 1px solid transparent;
        border-radius: 8px;
        width: 30%;
        height: 50px;
        font-size: 1.1em;
        font-weight: bold;
        color: white;
}
.verify-email-container > form button{
        background-color: var(--main-light-color);
        padding: 6px;
        border: 1px solid transparent;
        border-radius: 8px;
        width: 30%;
        height: 40px;
        font-size: 1.1em;
        font-weight: bold;
        color: white;
}
.verify-email-container > div button:hover{
      background-color: #aa8c2a;
}
.verify-email-container i{
  
        /* font-size: 2em;
        width: 100px;
        height: 60px; */
        font-size: 4em;
  
        /* line-height: 65px;
        background-color: var(--main-light-color);
        border-radius: 10px; */
        color: var(--main-light-color);
    
}
.verify-email-container h1{
        margin-top: -30px;
}
@media (max-width:600px) {
        .verify-email-container > div{
             width: 100%;
        border-radius: 0;
        } 
        .verify-email-container > form{
                width: 100%;
           border-radius: 0;
           } 
        .verify-email-container > div button{
                background-color: var(--main-light-color);
                width: 50%;
        }
        .verify-email-container h1{
                font-size: 1.7em
        }
}