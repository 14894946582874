.service-bagage-container{
    width: 100%;
    height: 100%;
    padding: 30px 30px 30px 30px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
}
.service-bagage-container a{
    text-decoration: none;
    color: white;
}
.service-bagage-container > div{
    height: 100%;
 
    display: flex;
    justify-content: space-evenly;
    text-align: center;
}
.right-bagages{
   padding-top: 4rem;
    display: flex;
    flex-direction: column;
   align-items: center;
   gap: 25px;
    text-align: center;
}
.right-bagages p{
    font-size: 1.1em;
    font-style: italic;
}
.right-bagages div:last-child{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
}
.service-bagage-container > div:first-child button{
    background-color: var(--main-light-color);
    height: 45px;
    font-weight: bold;
    font-size: 1em;
    color: white;
    width: 50%;
    border: 1px solid transparent;
    border-radius: 8px;
}
.service-bagage-container div img{
width: 50%;
height: auto;
position: relative;
top: 0;
}
.tarifs{
    display: flex;
    
    width: 100%;
    justify-content: space-evenly;
    margin-top: 2.5rem;
}
.tarifs div{
    padding: 10px;
  border-radius: 8px;
  --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
height: 400px;
width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    justify-content: space-evenly;
    font-weight: bold;
    transition-duration: 0.2s;
}
.tarifs div:hover{
    transform: scale(1.03);
}
.contact-bagages{
    margin-top: 35px;
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 30px;
}
.contact-bagages button{
    background-color: var(--main-light-color);
    height: 45px;
    font-weight: bold;
    font-size: 1em;
    color: white;
    width: 50%;
    border: 1px solid transparent;
    border-radius: 8px; 
}
@media (max-width:600px) {
    .tarifs{
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }
    .tarifs div{
        width: 90%;
    }
    .tarifs div img{
        width: 70%;
        height: auto;
    }
    .service-bagage-container > div{
       
        flex-direction: column;
   
     
    }
    .service-bagage-container > div img{
       width: 100%;
        height: auto;
      
        
        }
        .service-bagage-container{
            width: 100%;
            height: 100%;
            padding: 30px 30px 30px 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
}
