.commande-container{
    padding: 30px;
    margin-left: 250px;
}
.commande-container h1{
    font-family: "poopins",sans-serif;
    margin-top: 1rem;
    margin-bottom: 2rem; ;
    font-size: 1.5em;
    color: var(--main-dark-color);
}
.commande-container .sous-container{
    display: flex;
   gap: 35px;  
}
.commande-container .sous-container .left-commande{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 25px;
    background-color: white;
    border-radius: 8px;
    padding: 25px;
    width: 60%;
    height: fit-content;
    --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
}

.produit-commandé{
    display: flex;
   gap: 2.3rem;
    align-items: center;
    
}

.produit-commandé div:first-child{
    height: 100px;
    width: 100px;
    border: 1px solid rgba(128, 128, 128, 0.473);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}
.produit-commandé img{
width: 60px;
height: auto;
}
.produit-commandé > div:nth-child(2){
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-size: 0.93em;
 
}
.quantXprix{
    display: flex;
    font-size: 1em;
    font-weight: bold;
    color: rgba(128, 128, 128, 0.692);
}

.right-commande{
    display: flex;
    flex-direction: column;
   width: 30%;
    gap: 20px;
    
}
.right-commande > div{
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 8px;
    padding: 25px;
 
    --tw-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),var(--tw-shadow);
}
.client-info{
    display: flex;
    flex-direction: column;
gap: 12px;
}
.sous-container label{
    font-weight: bold;
    font-size: 0.93em;
    color: rgba(0, 0, 0, 0.842);
}
.client-info div{
    display: flex;
    flex-direction: column;
}
.client-titles{
    font-size: 0.87em;
    color: gray;
    margin-bottom: 8px;
}
.client-titles ~ span{
    font-size: 0.83em;
}
.statut-commande button{
width: 50%;
align-self: center;
height: 30px;
background-color: var(--main-light-color);
border: 1px solid transparent;
border-radius: 8px;
}
.statut-commande button:hover{
    background-color: #b3901e;
}
.total{
    display: flex;
    flex-direction: column;
    gap: 1rem;
     font-size: 0.93em;
     color: rgb(107, 107, 107);
}
.total span{
    display: flex;
    justify-content: space-between;
}
.total span span{
    color: var(--main-dark-color);
}
.id{
    cursor: pointer;
}
.id:hover{
    text-decoration: underline;
}
@media (max-width: 600px) {

 
}